import './styles.scss';
import { Link } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import { TableColumn } from 'react-data-table-component';
import { Table } from '../Table';
import { CustomTooltip, Typography } from 'Components/atoms';
import {
    ACTIVE_ENGAGEMENTS_MODAL_COLUMNS,
    ACTIVE_ENGAGEMENTS_MODAL_HEADER,
    ALL_TIME_ENGAGEMENTS_MODAL_HEADER,
    FINDINGS_GRID_MODAL_HEIGHT,
} from 'Core-utils/constants/constants';
import { ActiveEngagement, EngagementState, FilterCriteria, Page, _FlowStateStatusDto } from '@ampsec/platform-client';
import { USERS_DASHBOARD_ROUTE } from 'Core-utils/routes';
import moment from 'moment';
import { getGridData } from 'Apis/library';
import { useEffect, useMemo, useState } from 'react';

const ROWS_PER_PAGE_OPTIONS = [10, 20, 30];

interface ActiveEngagementsModalProps {
    variant: 'all' | 'active';
    specId: string;
    show: boolean;
    onClose: () => void;
    filterCriteria?: FilterCriteria;
}

type ActiveEngagements = {
    uid: string;
    userName: string;
    scheduledDate?: string | null;
    numOfReminders: number;
    openFor?: string | null;
    expirationIn?: string | null;
    scope?: ActiveEngagement['scope'];
    status: ActiveEngagement['status'];
    state?: EngagementState;
};

const columns: TableColumn<ActiveEngagements>[] = [
    {
        id: 'userName',
        name: (
            <Typography variant="subtitle6" className="m-0">
                {ACTIVE_ENGAGEMENTS_MODAL_COLUMNS.userName}
            </Typography>
        ),
        cell: (row: ActiveEngagements) => (
            <Link
                to={`${USERS_DASHBOARD_ROUTE}/${row.uid}`}
                className="engagement-clickable text-decoration-none text-truncate"
            >
                <Typography variant="body5" color="text-high-emphasis">
                    {row.userName}
                </Typography>
            </Link>
        ),
    },
    {
        id: 'openFor',
        name: (
            <Typography variant="subtitle6" className="m-0">
                {ACTIVE_ENGAGEMENTS_MODAL_COLUMNS.openFor}
            </Typography>
        ),
        cell: (row: ActiveEngagements) => (
            <Typography variant="subtitle3" className="m-0">
                {row.openFor ? moment(row.openFor).fromNow(true) : '-'}
            </Typography>
        ),
    },
    {
        id: 'scope',
        name: (
            <Typography variant="subtitle6" className="m-0">
                {ACTIVE_ENGAGEMENTS_MODAL_COLUMNS.scope}
            </Typography>
        ),
        cell: (row: ActiveEngagements) => (
            <Typography variant="subtitle3" className="m-0">
                {row.scope}
            </Typography>
        ),
    },
    {
        id: 'status',
        name: (
            <Typography variant="subtitle6" className="m-0">
                {ACTIVE_ENGAGEMENTS_MODAL_COLUMNS.status}
            </Typography>
        ),
        cell: (row: ActiveEngagements) => (
            <Typography variant="subtitle3" className="m-0">
                {row.status}
            </Typography>
        ),
    },
    {
        id: 'state',
        name: (
            <Typography variant="subtitle6" className="m-0">
                {ACTIVE_ENGAGEMENTS_MODAL_COLUMNS.state}
            </Typography>
        ),
        cell: (row: ActiveEngagements) => (
            <CustomTooltip
                label={row.state ?? '-'}
                tooltipClassName="m-0"
                labelVariant="subtitle3"
                labelColor="text-high-emphasis"
                overlayContainerClassName="text-truncate"
            >
                <Typography variant="subtitle3" className="m-0">
                    {row.state ?? '-'}
                </Typography>
            </CustomTooltip>
        ),
    },
    {
        id: 'noOfReminders',
        name: (
            <Typography variant="subtitle6" className="m-0">
                {ACTIVE_ENGAGEMENTS_MODAL_COLUMNS.noOfReminders}
            </Typography>
        ),
        center: true,
        cell: (row: ActiveEngagements) => (
            <Typography variant="subtitle3" className="m-0">
                {row.numOfReminders + 1}
            </Typography>
        ),
    },
    {
        id: 'expirationIn',
        name: (
            <Typography variant="subtitle6" className="m-0">
                {ACTIVE_ENGAGEMENTS_MODAL_COLUMNS.expirationIn}
            </Typography>
        ),
        center: true,
        cell: (row: ActiveEngagements) => (
            <Typography variant="subtitle3" className="m-0">
                {row.openFor && row.expirationIn ? moment(row.expirationIn).fromNow(true) : '-'}
            </Typography>
        ),
    },
    {
        id: 'scheduledDate',
        name: (
            <Typography variant="subtitle6" className="m-0">
                {ACTIVE_ENGAGEMENTS_MODAL_COLUMNS.scheduledDate}
            </Typography>
        ),
        cell: (row: ActiveEngagements) => (
            <Typography variant="subtitle3" className="m-0">
                {row.scheduledDate ? moment(row.scheduledDate).fromNow(true) : '-'}
            </Typography>
        ),
    },
];

const EngagementsTable = ({ specId, filter }: { specId: string; filter?: FilterCriteria }) => {
    const transformer = (data: ActiveEngagement): ActiveEngagements => ({
        uid: data.uid,
        userName: `${data.firstName} ${data.lastName}`,
        scheduledDate: data.scheduledDate,
        numOfReminders: data.numberOfReminders,
        openFor: data.engagementStartedAt,
        expirationIn: data?.expirationAt,
        scope: data.scope,
        status: data.status,
        state: data.state,
    });
    const [limit, setLimit] = useState(ROWS_PER_PAGE_OPTIONS[0]);
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState<Page<any> | undefined>();

    const filters = useMemo(() => {
        const offset = (page - 1) * limit;
        return { specId, ...filter, limit, offset };
    }, [specId, filter, limit, page]);

    useEffect(() => {
        setLoading(true);
        getGridData('flowSpecs', filters, 'getActiveEngagements')
            ?.then((res: any) => {
                setData(res);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [specId, filter, limit, page]);

    const changePageHandler = (currentPage: number) => {
        setPage(currentPage);
    };

    const changeRowsPerPageHandler = (rowsOption: number) => {
        setLimit(rowsOption);
    };

    return (
        <Table
            data={data ? data.data.map(transformer) : []}
            dataIsLoading={loading}
            columns={columns}
            rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
            cacheKey="flowSpecs"
            transformer={(dataItem) => ({ ...dataItem, data: dataItem.data.map(transformer) })}
            showSubHeader={false}
            tableBodyHeight={FINDINGS_GRID_MODAL_HEIGHT}
            onChangePage={changePageHandler}
            onRowChange={changeRowsPerPageHandler}
            estimated={data?.hints.estimate}
            currentPage={page}
            currentLimit={limit}
            paginationTotalRows={data?.hints?.count ?? 0}
        />
    );
};

const EngagementsModal = ({ variant = 'all', specId, show, onClose, filterCriteria }: ActiveEngagementsModalProps) => {
    const filter = variant === 'all' ? undefined : { ...filterCriteria, status: _FlowStateStatusDto.Values.RUNNING };
    const header = variant === 'all' ? ALL_TIME_ENGAGEMENTS_MODAL_HEADER : ACTIVE_ENGAGEMENTS_MODAL_HEADER;
    return (
        <Modal className="active-engagements-modal w-100" show={show} onHide={onClose} centered size="xl">
            <Modal.Header
                className="active-engagements-modal__header text-text-high-emphasis"
                closeButton
                closeVariant="white"
            >
                <Typography className="m-0 text-uppercase" variant="h4">
                    {header}
                </Typography>
            </Modal.Header>
            <Modal.Body className="active-engagements-modal__body border-top-0 p-0 rounded-bottom">
                <EngagementsTable specId={specId} filter={filter} />
            </Modal.Body>
        </Modal>
    );
};

export default EngagementsModal;
