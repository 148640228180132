import { AssetDto, FindingDto, KIND, UserDto } from '@ampsec/platform-client';
import { getAssetById, getFindings, getUserDetails } from 'Apis/library';
import { useParams } from 'react-router-dom';
import { Accordion } from 'Components/atoms';
import { AccordionItemProps } from 'Components/atoms/Accordion/Accordion';
import { EngagementDetailsAccordion } from '../EngagementDetailsAccordion';
import { useApiObjectData } from 'Hooks/useApiObjectData';
import { InfoCard } from 'Components/molecules/InfoCard';
import ErrorHandlerAndLoaderWrapper from 'Wrappers/ErrorHandlerandLoaderWrapper';
import EngageIcon from 'Assets/icons/engagement.svg';
import _ from 'lodash';

const FindingDetailContentWrapper = () => {
    const { id: findingId } = useParams();
    const { data: findingData, error, isLoading, refresh } = useApiObjectData<FindingDto, FindingDto>(
        KIND.FINDINGS,
        () => getFindings({ id: findingId, include: ['meta', 'asset', 'provider', 'user'] }),
        (a) => a,
    );
    const {
        data: userData,
        error: userDataError,
        isLoading: userDataIsLoading,
        refresh: userDataRefresh,
    } = useApiObjectData<UserDto, UserDto>(
        findingData?.uid ?? '',
        findingData?.uid ? getUserDetails : () => Promise.resolve(null),
    );

    const {
        data: assetData,
        error: assetDataError,
        isLoading: assetDataIsLoading,
        refresh: assetDataRefresh,
    } = useApiObjectData<AssetDto, AssetDto>(
        findingData?.aid ?? '',
        findingData?.aid ? getAssetById : () => Promise.resolve(null),
    );

    const findingsDataMap: { key: string; value: string }[] = [
        {
            key: 'Description',
            value: findingData?.description ?? '-',
        },
        {
            key: 'Severity',
            value: findingData?.severity ?? '-',
        },
        {
            key: 'Status',
            value: findingData?.status ?? '-',
        },
        {
            key: 'Issue Type',
            value: findingData?.category ?? '-',
        },
        {
            key: 'Provider',
            value: findingData?.provider?.displayValue ?? '-',
        },
        {
            key: 'User',
            value:
                `${findingData?.user?.firstName ?? ''} ${findingData?.user?.lastName ?? ''}`.trim().toString() ?? '-',
        },
        {
            key: 'Department',
            value: findingData?.user?.department ?? '-',
        },
        {
            key: 'Device',
            value: findingData?.asset?.displayValue ?? '-',
        },
        {
            key: 'Discovered At',
            value: findingData?.discoveredAt ?? '-',
        },
    ];
    const userDataMap: { key: string; value: string }[] = [
        {
            key: 'Name',
            value: `${userData?.firstName ?? ''} ${userData?.lastName ?? ''}`.trim().toString() ?? '-',
        },
        {
            key: 'Email',
            value: userData?.emails?.[0] ?? '-',
        },
        {
            key: 'Department',
            value: userData?.department ?? '-',
        },
        {
            key: 'Score',
            value: userData?.score?.toString() ?? '-',
        },
        {
            key: 'Last Activity',
            value: userData?.lastActivityTime ?? '-',
        },
    ];
    const assetDataMap: { key: string; value: string }[] = [
        {
            key: 'Display Name',
            value: assetData?.displayValue ?? '-',
        },
        {
            key: 'Serial Number',
            value: assetData?.extKeys?.sn ?? '-',
        },
        {
            key: 'Host Name',
            value: assetData?.extKeys?.hostname ?? '-',
        },
        {
            key: 'Last CheckIn',
            value: assetData?.lastActivityTime ?? '-',
        },
    ];
    const rawFindingsDataMap: { key: string; value: string }[] = [
        {
            key: 'Event Type',
            value: findingData?.meta?._custom?.eventType ?? '-',
        },
        {
            key: 'Vulnerability CVE',
            value: findingData?.meta?._vulnerability?.cve ?? '-',
        },
        {
            key: 'Vulnerability CVSS',
            value: findingData?.meta?._vulnerability?.cvss?.toString() ?? '-',
        },
        {
            key: 'Training tile',
            value: findingData?.meta?._training?.title ?? '-',
        },
        {
            key: 'Enroll Date',
            value: findingData?.meta?._training?.enrollDate ?? '-',
        },
    ];
    const accordionItems: AccordionItemProps[] = [
        {
            key: '0',
            eventKey: '0',
            title: 'FINDINGS DETAILS',
            isConfigurationRequired: false,
            children: (
                <ErrorHandlerAndLoaderWrapper
                    error={error}
                    isLoading={isLoading}
                    handleRefresh={refresh}
                    ImageSrc={EngageIcon}
                    render={() => <InfoCard dataMap={findingsDataMap} data-testid="findings-data-map" />}
                    isEmpty={_.isEmpty(findingData)}
                />
            ),
        },
        {
            key: '1',
            eventKey: '1',
            title: 'ENGAGEMENTS DETAILS',
            isConfigurationRequired: false,
            children: <EngagementDetailsAccordion findingData={findingData as FindingDto} />,
        },
        {
            key: '2',
            eventKey: '2',
            title: 'USER DETAILS',
            isConfigurationRequired: false,
            children: (
                <ErrorHandlerAndLoaderWrapper
                    error={userDataError}
                    isLoading={userDataIsLoading}
                    handleRefresh={userDataRefresh}
                    ImageSrc={EngageIcon}
                    render={() => <InfoCard dataMap={userDataMap} />}
                    isEmpty={_.isEmpty(userData)}
                />
            ),
        },
        {
            key: '3',
            eventKey: '3',
            title: 'ASSET DETAILS',
            isConfigurationRequired: false,
            children: (
                <ErrorHandlerAndLoaderWrapper
                    error={assetDataError}
                    isLoading={assetDataIsLoading}
                    handleRefresh={assetDataRefresh}
                    ImageSrc={EngageIcon}
                    render={() => <InfoCard dataMap={assetDataMap} />}
                    isEmpty={_.isEmpty(assetData) || findingData?.aid === undefined || findingData?.aid === null}
                    description="No Asset Found"
                />
            ),
        },
        {
            key: '4',
            eventKey: '4',
            title: 'RAW FINDINGS DETAILS',
            isConfigurationRequired: false,
            children: (
                <ErrorHandlerAndLoaderWrapper
                    error={error}
                    isLoading={isLoading}
                    handleRefresh={refresh}
                    ImageSrc={EngageIcon}
                    render={() => <InfoCard dataMap={rawFindingsDataMap} />}
                    isEmpty={_.isEmpty(findingData)}
                />
            ),
        },
    ];

    return (
        <Accordion
            accordionItems={accordionItems}
            defaultActiveKey={['0', '1', '2', '3', '4']}
            alwaysOpen={true}
            data-testid="finding-detail-content"
        />
    );
};

export default FindingDetailContentWrapper;
