import { Typography } from 'Components/atoms';
import './styles.scss';

interface FindingsCardProps {
    dataMap: { key: string; value: string }[];
}

const renderFindingInfo = (key: string, value: string) => {
    return (
        <div className="info-card__item  d-flex flex-row align-items-center w-100">
            <div className="info-card__styled-category d-flex justify-content-between">
                <div className="colon">
                    <Typography variant="body3" color="text-medium-emphasis" className="mb-0" children={key} />
                </div>
            </div>
            <Typography
                variant="body2"
                className="text-truncate info-card__text-ellipsis"
                style={{ maxWidth: '70%' }}
                color="text-medium-emphasis"
                children={value}
            />
        </div>
    );
};

const FindingsCard = ({ dataMap }: FindingsCardProps) => {
    return (
        <div className="d-flex flex-row w-100 p-4 gap-5">
            <div className="d-flex flex-column w-100">
                {dataMap.map((info, index) => (
                    <div key={index} className="w-100 d-flex flex-row align-items-center gap-2">
                        {renderFindingInfo(info.key, info.value)}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default FindingsCard;
