import { CustomCard } from 'Components/molecules';
import { IconWithTypography } from 'Components/molecules';
import { DonutGraph } from 'Components/molecules';
import { TOP_RISK_CONTRIBUTORS } from 'Core-utils/constants/constants';
import { DonutGraphDataSet } from 'Core-utils/types';
import ErrorHandlerAndLoaderWrapper from 'Wrappers/ErrorHandlerandLoaderWrapper';

const COMPONENT_IDENTIFIER = 'Department Top Risk Contributors';

interface UserTopRiskContributorsProps {
    datasets: DonutGraphDataSet[];
    error: any;
    isLoading: boolean;
    onRefresh: React.MouseEventHandler<HTMLButtonElement>;
    isEmpty: boolean;
    imageSrc: string;
}

const UserTopRiskContributors = ({
    datasets,
    error,
    isLoading,
    onRefresh,
    isEmpty,
    imageSrc,
}: UserTopRiskContributorsProps) => {
    const UserRiskContributorsChart = () => {
        return <DonutGraph datasets={datasets} />;
    };
    return (
        <div className="user-top-risk-contributors h-100 w-100" data-testid="user-top-risk-contributors">
            <CustomCard
                cardType="oneThird"
                title={<IconWithTypography isRow={true} label={TOP_RISK_CONTRIBUTORS} labelColor="primary" />}
                content={
                    <ErrorHandlerAndLoaderWrapper
                        error={error}
                        render={() => <UserRiskContributorsChart />}
                        isLoading={isLoading}
                        handleRefresh={onRefresh}
                        isEmpty={isEmpty}
                        ImageSrc={imageSrc}
                        component={COMPONENT_IDENTIFIER}
                    />
                }
            />
        </div>
    );
};

export default UserTopRiskContributors;
