import axios from 'axios';
import {
    CreateNotificationDto,
    FilterCriteria,
    FindingPatchDto,
    AmpSettingUpsertDto,
    ProviderDto,
    TenantDto,
    ConnectorUpsertDto,
    RawAmpSettingsMap,
    ConnectorStatus,
    FlowSpecFilter,
    FlowSpecUpsertDto,
    FindingSpecDto,
    CustomScoreValueDto,
    CustomActionDto,
    ContentService,
    CustomActionUpsertDto,
    AgentDto,
    CustomScoreCohortDto,
    AgentInviteDto,
    CustomScoreCohortUpsertDto,
} from '@ampsec/platform-client';
import { getApi } from 'Src/Api';
import { GridType } from 'Core-utils/types/types';
import { AIServiceModelName } from 'Core-utils/constants/constants';

axios.defaults.baseURL = process.env.PLATFORM_API_URL;

export const getMetricsReportsData = async (inputKey: string, filter?: FilterCriteria) => {
    const api = await getApi();
    const response = await api?.reports.getMetrics(inputKey, filter);
    return response && response.data[0]?.results;
};

export const getMetricsHealthScoreData = async (inputKey: string, filter?: FilterCriteria) => {
    const api = await getApi();
    const response = await api?.reports.getMetrics(inputKey, filter);
    return response && response.data;
};

export const getMetricsTrendsReportsData = async (inputKey: string, filter?: FilterCriteria) => {
    const api = await getApi();
    const response = await api?.reports.getMetricsTrend(inputKey, filter);
    return response && response.data;
};

export const getEnum = async (inputUrl: string, filter: FilterCriteria) => {
    const api = await getApi();
    const response = await (api?.enums as Record<string, any>)[inputUrl].list(filter);
    return response && response;
};

export const getUserDetails = async (userId: string) => {
    const api = await getApi();
    const response = await api?.users.getById(userId);
    return response && response.data[0];
};

export const getTenantsForCurrentUser = async (_inputUrl: string, filter?: FilterCriteria): Promise<TenantDto[]> => {
    const api = await getApi();
    const response = await api?.tenants.list(filter ?? {});
    return response?.data || [];
};

export const exchangeTokenForAnotherTenant = async (targetIenantId: string): Promise<string> => {
    const api = await getApi();
    const { token } = (await api?.identity.exchangeToken(targetIenantId)) || { token: '' };
    return token;
};

//TODO:Remove once all Live APIs are available
export const getApiData = async (inputUrl: string) => {
    axios.defaults.baseURL = process.env.REACT_MOCK_API_URL;
    axios.defaults.headers.common['Authorization'] = `Bearer ${process.env.REACT_MOCK_API_BEARER_TOKEN}`;

    const response = await axios.get(`/${inputUrl}`);
    return response && response.data.data;
};

export const getGridData = async (inputUrl: GridType, filter?: FilterCriteria, operation = 'list') => {
    const api = await getApi();
    const response = await (api as Record<string, any>)[inputUrl][operation](filter);
    if (response?.error) {
        throw response.error;
    }
    return response && response;
};

export const getEnums = async (inputUrlsJoin: string, filter: FilterCriteria) => {
    const inputUrls = inputUrlsJoin.split('__').filter((inputUrl) => inputUrl !== '');
    const promises = inputUrls.map((inputUrl) => getEnum(inputUrl, filter));
    const response = await Promise.allSettled(promises);
    return response;
};

export const getProvidersData = async (filter: FilterCriteria): Promise<ProviderDto[]> => {
    const api = await getApi();
    const response = await api?.providers.list(filter);
    return response?.data || [];
};

export const postEngageNotifications = async (postData: CreateNotificationDto[]) => {
    const api = await getApi();
    const response = await api?.notifications.createNew(postData);
    return response;
};

export const getInstalledConnectors = async (filter: FilterCriteria) => {
    const api = await getApi();
    const response = await api?.connectors.list(filter);
    return response?.data;
};

export const getFindings = async (filter: FilterCriteria) => {
    const api = await getApi();
    const response = await api?.findings.list(filter);
    return response && response.data[0];
};

export const patchFindingsStatus = async (findings: FindingPatchDto[]) => {
    const api = await getApi();
    const response = await api?.findings.patchAll(findings);
    return response;
};
export const createSetting = async (key: string, value: unknown, cid?: string) => {
    const api = await getApi();
    const response = await api?.settings.create(key, value, cid);
    return response;
};

export const updateSettings = async (id: string, entity: AmpSettingUpsertDto) => {
    const api = await getApi();
    const response = await api?.settings.update(id, entity);
    return response;
};

export const getTenantSettings = async (): Promise<RawAmpSettingsMap | undefined> => {
    const api = await getApi();
    const response = await api?.settings.getSettingsMap();
    return response?.getRawMap()?.tenantSettings;
};

export const installConnector = async (connectorModel: ConnectorUpsertDto) => {
    if (!connectorModel.pid) {
        throw new Error('Provider Id is required');
    }
    const api = await getApi();
    const response = await api?.connectors.create(connectorModel.pid, connectorModel.displayValue);
    return response?.id;
};

export const updateConnectorStatus = async (id: string, status: ConnectorStatus.ACTIVE | ConnectorStatus.DISABLED) => {
    const api = await getApi();
    const response = await api?.connectors.setStatus(id, status);
    return response;
};

export const updateAllSettings = async (
    data: {
        id: string;
        value: unknown;
    }[],
) => {
    const api = await getApi();
    const response = await api?.settings.updateAll(data);
    return response;
};

export const getConnectorSettings = async (tid: string) => {
    const api = await getApi();
    const response = await api?.settings.getSettingsMap(tid);
    return response && response;
};

export const removeSetting = async (id: string) => {
    const api = await getApi();
    const response = await api?.connectors.delete(id);
    return response;
};

export const createPrediction = async (model: AIServiceModelName, question: string) => {
    const api = await getApi();
    const response = await api?.prediction.create({
        model: model,
        question: [question],
    });
    return response;
};

export const getFindingsView = async (filterCriteria: any, queryParams: FilterCriteria) => {
    const api = await getApi();
    const response = await api?.findingsInsights.create(filterCriteria, queryParams);
    if (response?.error) {
        throw response.error;
    }
    return response;
};

export const getEngagementFlow = async (_inputKey: string, filterCriteria: FlowSpecFilter) => {
    const api = await getApi();
    const response = await api?.flowSpecs.getById(filterCriteria.id ?? '');
    if (response?.error) {
        throw response.error;
    }
    return response;
};

export const updateEngagementFlow = async (filterCriteria: FlowSpecUpsertDto) => {
    const api = await getApi();
    const response = await api?.flowSpecs.update(filterCriteria);
    if (response?.error) {
        throw response.error;
    }
    return response;
};

export const createFlow = async (flowSpec: FlowSpecUpsertDto) => {
    const api = await getApi();
    const response = await api?.flowSpecs.createFromName(flowSpec);
    return response;
};

export const deleteFlowById = async (id: string) => {
    const api = await getApi();
    const response = await api?.flowSpecs.delete(id);
    if (response?.error) {
        throw response.error;
    }
    return response;
};

export const getCohorts = async (query?: string) => {
    const api = await getApi();
    const response = await api?.cohorts.list(query);
    return response?.data;
};

export const getFindingSpecs = async (inputKey: string, filter: FilterCriteria) => {
    const api = await getApi();
    const response = await api?.findingSpecs.list(filter);
    return response?.data;
};

export const updateFindings = async (model: FindingSpecDto) => {
    const api = await getApi();
    const response = await api?.findingSpecs.update(model);
    if (response?.error) {
        throw response.error;
    }
    return response;
};

export const deleteFindingsById = async (id: string) => {
    const api = await getApi();
    const response = await api?.findingSpecs.delete(id);
    if (response?.error) {
        throw response.error;
    }
    return response;
};

export const addFindingSpecs = async (model: FindingSpecDto) => {
    const api = await getApi();
    const response = await api?.findingSpecs.create(model);
    return response;
};

export const getHighRiskScore = async (_inputKey: string, query: FilterCriteria) => {
    const api = await getApi();
    const response = await api?.customScoreValues.list(query);
    return response?.data;
};

export const createHighRiskScore = async (data: Omit<CustomScoreValueDto, 'createdAt' | 'updatedAt' | 'deletedAt'>) => {
    const api = await getApi();
    const response = await api?.customScoreValues.create(data);
    return response;
};

export const updateHighRiskScore = async (data: Omit<CustomScoreValueDto, 'createdAt' | 'updatedAt' | 'deletedAt'>) => {
    const api = await getApi();
    const response = await api?.customScoreValues.update(data);
    return response;
};

export const deleteHighRiskScore = async (id: string) => {
    const api = await getApi();
    const response = await api?.customScoreValues.delete(id);
    return response?.data;
};

export const getAllActions = async (inputKey: string, filter: FilterCriteria) => {
    const api = await getApi();
    const response = await api?.customActions.list(filter);
    return response?.data;
};

export const addAction = async (model: CustomActionUpsertDto) => {
    const api = await getApi();
    const response = await api?.customActions.create(model);
    return response;
};

export const getActionById = async (id: string) => {
    const api = await getApi();
    const response = await api?.customActions.getById(id);
    return response && response?.data[0];
};

export const updateActionById = async (model: CustomActionDto) => {
    const api = await getApi();
    const response = await api?.customActions.update(model);
    return response;
};

export const deleteActionById = async (id: string) => {
    const api = await getApi();
    const response = await api?.customActions.delete(id);
    return response;
};

export const fetchServiceProfiles = async () => {
    const response = await ContentService.instance().getServiceProfiles();
    return response;
};

export const getActionsSummary = async (inputKey: string, filter: FilterCriteria) => {
    const api = await getApi();
    const response = await api?.customActions.summary(filter);
    return response?.data;
};

export const getHighRiskCohorts = async (_inputKey: string, query: FilterCriteria) => {
    const api = await getApi();
    const response = await api?.customScoreCohorts.list(query);
    return response?.data;
};

export const createHighRiskCohorts = async (data: CustomScoreCohortUpsertDto) => {
    const api = await getApi();
    const response = await api?.customScoreCohorts.create(data);
    return response;
};

export const updateHighRiskCohorts = async (
    data: Omit<CustomScoreCohortDto, 'createdAt' | 'updatedAt' | 'deletedAt'>,
) => {
    const api = await getApi();
    const response = await api?.customScoreCohorts.update(data);
    return response;
};

export const deleteHighRiskCohort = async (value: string) => {
    const api = await getApi();
    const response = await api?.customScoreCohorts.delete(value);
    return response?.data;
};

export const getActionExecutions = async (inputKey: string, filter: FilterCriteria) => {
    const api = await getApi();
    const response = await api?.actionExecutions.list(filter);
    return response?.data;
};
export const getAgentsList = async (inputUrl: string, filter: FilterCriteria) => {
    const api = await getApi();
    const response = await api?.agents.list(filter);
    return response?.data;
};

export const inviteAgent = async (model: AgentInviteDto): Promise<AgentDto> => {
    const api = await getApi();
    const response = await api?.agents.invite(model);
    return (response as unknown) as AgentDto;
};

export const UpdateAgent = async (modal: AgentDto) => {
    const api = await getApi();
    const response = await api?.agents.update(modal);
    return response?.data;
};

export const deleteAgent = async (id: string) => {
    const api = await getApi();
    const response = await api?.agents.delete(id);
    return response?.data;
};

export const getWebhookURL = async (_inputKey: string, filter: FilterCriteria) => {
    const api = await getApi();
    const response = await api?.tokens.list(filter);
    return response?.data;
};

export const triggerEngagement = async (fids: Array<string>, forceTrigger: boolean, allowDuplicates = true) => {
    const api = await getApi();
    const response = await api?.engagements.trigger({
        fids,
        allowDuplicates,
        overrideGhostMode: false,
        forceTrigger,
    });
    return response;
};

export const getAssetById = async (id: string) => {
    const api = await getApi();
    const response = await api?.asset.getById(id);
    return response && response.data[0];
};

export const getProfile = async (token: string) => {
    return await fetch(`${process.env.PLATFORM_API_URL}/me`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};
