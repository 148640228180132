import { useMemo, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import InviteSubHeaderContent from './InvitesSubHeaderContent';
import {
    ACCESS,
    ACCESS_OPTIONS,
    ADDED_DATE,
    DATA_GRID_HEIGHT,
    EMAIL_ID,
    LAST_LOGGED_IN,
    NAME,
    STATUS,
    USER_RBAC_OPTIONS,
    USER_TABLE_ROWS_PER_PAGE_OPTIONS,
    YES_DELETE_IT,
} from 'Core-utils/constants/constants';
import { deleteAgent, getAgentsList, inviteAgent, UpdateAgent } from 'Apis/library';
import { AgentAuthRoles, AgentDto, AgentStatus, AuthRole, KIND } from '@ampsec/platform-client';
import { useApiObjectData } from 'Hooks/useApiObjectData';
import { MenuItem } from 'Core-utils/types';
import { ConfirmationModal } from 'Components/molecules';
import { RiDeleteBin6Line } from '@react-icons/all-files/ri/RiDeleteBin6Line';
import { Avatar, Chip, CustomTooltip, Typography } from 'Components/atoms';
import { TableColumn } from 'react-data-table-component';
import RoleDropdown from './RoleDropdown';
import { RiArrowDownSLine } from '@react-icons/all-files/ri/RiArrowDownSLine';
import _ from 'lodash';
import { Table } from 'Components/organisms';

const shareSecurityPostureColumns = (
    fetchAgents: AgentDto[],
    setRefreshData: React.Dispatch<React.SetStateAction<boolean>>,
    handleDeleteUser: (id: string) => void,
): TableColumn<AgentDto>[] => {
    return [
        {
            id: 'firstName',
            name: <Typography variant="body2">{NAME}</Typography>,
            sortable: true,
            wrap: true,
            cell: (row: AgentDto) => {
                return row.firstName ? (
                    <Avatar
                        variant={'name_avatar'}
                        name={row?.firstName ? row?.firstName + ' ' + row?.lastName : ''}
                        image={row?.pictureUrl ?? undefined}
                    />
                ) : (
                    <Typography variant="subtitle3" children=""></Typography>
                );
            },
        },
        {
            id: 'createdAt',
            name: <Typography variant="body2">{ADDED_DATE}</Typography>,
            sortable: true,
            wrap: true,
            cell: (row: AgentDto) => {
                return <Typography variant="subtitle3">{row.createdAt}</Typography>;
            },
        },
        {
            id: 'emailId',
            name: <Typography variant="body2">{EMAIL_ID}</Typography>,
            sortable: false,
            wrap: true,
            cell: (row: AgentDto) => {
                return (
                    <CustomTooltip
                        id={`user-grid-email-${row.id}`}
                        label={row.email ?? ''}
                        labelColor="primary"
                        labelVariant="subtitle3"
                        overlayPlacement="left"
                        overlayContainerClassName="d-flex flex-column justify-content-center overflow-hidden w-100"
                        tooltipClassName="color-white text-white"
                    >
                        <Typography variant="subtitle3" style={{ width: '11rem' }} className="text-truncate">
                            {row.email}
                        </Typography>
                    </CustomTooltip>
                );
            },
        },
        {
            id: 'lastLoggedIn',
            name: <Typography variant="body2">{LAST_LOGGED_IN}</Typography>,
            sortable: false,
            wrap: true,
            cell: (row: AgentDto) => {
                return <Typography variant="subtitle3">{row.lastLoggedIn?.toString()}</Typography>;
            },
        },
        {
            id: 'access',
            name: <Typography variant="body2">{ACCESS}</Typography>,
            sortable: false,
            wrap: true,
            cell: (row: AgentDto) => {
                const [value, setValue] = useState<AuthRole>(row.role);

                const handleDropdownChange = (eventKey: string | null, _e: any) => {
                    const agentToUpdate = fetchAgents?.find((item: AgentDto) => item.id === row.id);
                    if (agentToUpdate?.id) {
                        UpdateAgent({
                            ..._.omit(agentToUpdate, 'pictureUrl'),
                            role: eventKey?.toUpperCase() as AgentAuthRoles,
                            pictureUrl: agentToUpdate.pictureUrl,
                        }).then(() => {
                            setRefreshData((refreshData) => !refreshData);
                            setValue(eventKey?.toUpperCase() as AuthRole);
                        });
                    }
                };
                return <RoleDropdown dropDownItems={ACCESS_OPTIONS} value={value} onChange={handleDropdownChange} />;
            },
        },
        {
            id: 'status',
            name: <Typography variant="body2">{STATUS}</Typography>,
            sortable: false,
            wrap: true,
            cell: (row: AgentDto) => {
                const [value, setValue] = useState<string>(row.status);
                const handleUpdateStatus = async (rowId: string, status: AgentStatus) => {
                    const agentToUpdate = fetchAgents?.find((item: AgentDto) => item.id === rowId);
                    if (agentToUpdate?.id) {
                        try {
                            await UpdateAgent({
                                ..._.omit(agentToUpdate, 'pictureUrl'),
                                status: status,
                                pictureUrl: agentToUpdate.pictureUrl,
                            });

                            setRefreshData((refreshData) => !refreshData);
                            return true;
                        } catch (error) {
                            return false;
                        }
                    } else {
                        return false;
                    }
                };

                let isUpdateSuccess = false;

                const handleDropdownChange = async (eventKey: string | null, _e: any) => {
                    switch (eventKey) {
                        case 'ACTIVATE USER':
                            isUpdateSuccess = await handleUpdateStatus(row.id ?? '', AgentStatus.ACTIVE);
                            if (isUpdateSuccess) {
                                setValue('ACTIVE');
                            }
                            break;
                        case 'SUSPEND USER':
                            isUpdateSuccess = await handleUpdateStatus(row.id ?? '', AgentStatus.SUSPENDED);
                            if (isUpdateSuccess) {
                                setValue('SUSPENDED');
                            }
                            break;
                        default:
                            break;
                    }
                };
                const dropdownItems = USER_RBAC_OPTIONS.filter(
                    (option) => option.event !== value && option.event !== 'REMOVE',
                );
                return row.status !== AgentStatus.INVITED ? (
                    <Dropdown onSelect={handleDropdownChange}>
                        <Dropdown.Toggle className="border-0 text-text-low-emphasis d-flex gap-2 justify-content-center align-items-center">
                            <Chip value={value} variant="status" />
                            <RiArrowDownSLine />
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="p-0 w-100 gap-2 bg-body-color border-text-medium-emphasis custom-scrollbar dropdown__custom-menu">
                            {dropdownItems.map((item) => (
                                <Dropdown.Item
                                    key={item.id}
                                    eventKey={item.value}
                                    className="dropdown__menu-item d-flex align-items-center gap-2"
                                >
                                    <Typography variant={'body5'} style={{ textTransform: 'uppercase' }}>
                                        {item.value}
                                    </Typography>
                                </Dropdown.Item>
                            ))}
                        </Dropdown.Menu>
                    </Dropdown>
                ) : (
                    <div className="d-flex ps-2">
                        <Chip value={row.status} variant="status" />
                    </div>
                );
            },
        },
        {
            id: 'delete',
            width: '5rem',
            cell: (row: AgentDto) => {
                return (
                    <RiDeleteBin6Line
                        size={20}
                        className="text-text-low-emphasis"
                        cursor="pointer"
                        onClick={() => handleDeleteUser(row.id)}
                    />
                );
            },
        },
    ];
};
const UserManagementGrid = () => {
    const [inputValue, setInputValue] = useState<string>('');
    const [isValidEmail, setIsValidEmail] = useState<boolean>(true);
    const [dropdownValue, setDropdownValue] = useState<string>(ACCESS_OPTIONS[0].value);
    const [refreshData, setRefreshData] = useState<boolean>(false);
    const [disableInvite, setDisableInvite] = useState<boolean>(false);
    const [idToDelete, setIdToDelete] = useState<string>('');
    const handleDeleteUser = (id: string) => {
        setShowConfirmationUserManagementModal(true);
        setIdToDelete(id);
    };
    const [showConfirmationUserManagementModal, setShowConfirmationUserManagementModal] = useState<boolean>(false);

    const { data: fetchInvitedAgents, refresh: refreshInvitedAgents } = useApiObjectData<AgentDto[], AgentDto[]>(
        KIND.AGENTS,
        getAgentsList,
        (a) => a,
        {
            status: AgentStatus.INVITED,
        },
    );
    const { data: fetchAgents, refresh } = useApiObjectData<AgentDto[], AgentDto[]>(
        KIND.AGENTS,
        getAgentsList,
        (a) => a,
    );
    const handleDropdownChange = (eventKey: string | null, _e: any) => {
        setDropdownValue(eventKey ?? '');
    };

    const handleInputChange = (event: any) => {
        setInputValue(event.target.value);
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        setIsValidEmail(emailRegex.test(event.target.value));
    };
    const handleInviteUser = () => {
        isValidEmail &&
            inviteAgent({
                email: inputValue,
                role: dropdownValue.toUpperCase() as AgentAuthRoles,
            }).then(() => {
                setDisableInvite(true);
                setRefreshData((refreshData) => !refreshData);
                refreshInvitedAgents();
                refresh();
                setInputValue('');
                setDisableInvite(false);
            });
    };
    const mapFetchedAgentItem = (item: AgentDto): MenuItem => {
        return {
            id: item.id,
            displayValue: item?.email,
            value: item?.email,
        };
    };
    const invitedList = useMemo(() => {
        if (Array.isArray(fetchInvitedAgents)) {
            const updatedList = fetchInvitedAgents?.map((item: AgentDto) => mapFetchedAgentItem(item));
            return updatedList;
        }
    }, [fetchInvitedAgents]);
    const passthrough = (a: any) => a;
    const handleUserDeleteClick = () => {
        deleteAgent(idToDelete).then(() => {
            setRefreshData((refreshData) => !refreshData);
            refreshInvitedAgents();
            refresh();
        });
        setShowConfirmationUserManagementModal(false);
    };
    const handleCloseUserManagementModal = () => {
        setShowConfirmationUserManagementModal(false);
    };
    return (
        <>
            <Table
                columns={shareSecurityPostureColumns(fetchAgents, setRefreshData, handleDeleteUser)}
                rowsPerPageOptions={USER_TABLE_ROWS_PER_PAGE_OPTIONS}
                showSubHeader={true}
                tableBodyHeight={DATA_GRID_HEIGHT}
                subHeaderVariant="with-button"
                subHeaderContent={
                    <InviteSubHeaderContent
                        onInputChange={handleInputChange}
                        emailInput={inputValue}
                        isValidEmail={isValidEmail}
                        dropdownValue={dropdownValue}
                        onDropdownChange={handleDropdownChange}
                        addUser={handleInviteUser}
                        invitedUserList={invitedList ?? []}
                        disableInviteButton={disableInvite}
                    />
                }
                pagination={false}
                cacheKey="agents"
                transformer={passthrough}
                searchKey="email"
                refreshTableData={refreshData}
            />
            <ConfirmationModal
                modalTitle={'REMOVE USER'}
                modalBodyTitle={'Are you sure you want to remove this user?'}
                onPrimaryButtonClick={handleUserDeleteClick}
                primaryButtonLabel={YES_DELETE_IT}
                show={showConfirmationUserManagementModal}
                onClose={handleCloseUserManagementModal}
                buttonVariant="danger"
            />
        </>
    );
};

export default UserManagementGrid;
