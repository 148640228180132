import { Typography } from 'Components/atoms';
import { EngagementModalVariant } from 'Components/pages/EngagementFlowPage/engagementFlowTable';
import { FINDINGS_TABLE_PAGINATION, FLOW_HEADER_TEXTS } from 'Core-utils/constants/constants';
import { TableColumn } from 'react-data-table-component';
import { MdLink } from '@react-icons/all-files/md/MdLink';
import { Link } from 'react-router-dom';
import { DEFAULT_WORKFLOW_ROUTE } from 'Core-utils/routes';
import { FindingDto, FindingKind, FlowSpecDto, FlowSpecStatusKind, Page } from '@ampsec/platform-client';
import { EngagementsModal } from 'Components/organisms/ActiveEngagementsModal';
import { Table } from '../Table';
import { useMemo, useState } from 'react';
import { getGridData } from 'Apis/library';
import { useApiObjectData } from 'Hooks/useApiObjectData';
import _, { startCase } from 'lodash';
import classNames from 'classnames';
import './styles.scss';

export const ENGAGEMENT_FLOW_TABLE_HEADER = (
    handleShowActiveEngagementModal: (flowSpecId: string, modalVariant: EngagementModalVariant) => void,
): TableColumn<FlowSpecDto>[] => {
    return [
        {
            id: 'name',
            width: '25rem',
            name: (
                <Typography variant="body2" color="text-black">
                    {FLOW_HEADER_TEXTS[0]}
                </Typography>
            ),
            sortable: true,

            style: {
                cursor: 'pointer',
            },
            cell: (row: FlowSpecDto) => {
                return (
                    <Link
                        to={`${DEFAULT_WORKFLOW_ROUTE}/create-engagement/${row.id}`}
                        className="engagement-clickable text-decoration-none text-truncate"
                    >
                        <Typography variant="body5" color="text-high-emphasis">
                            {row.name}
                        </Typography>
                    </Link>
                );
            },
        },

        {
            id: 'filter',
            name: (
                <Typography variant="body2" color="text-black">
                    {FLOW_HEADER_TEXTS[1]}
                </Typography>
            ),
            sortable: false,
            cell: (row: FlowSpecDto) => {
                const cohorts = row.filter?.cohorts || [];

                return (
                    <Typography variant="body5" color="text-high-emphasis">
                        {cohorts.length === 0
                            ? 'ALL USERS'
                            : cohorts.map((item, index) => {
                                  const className = classNames({
                                      'text-decoration-line-through': !item?.inclusive,
                                  });
                                  return (
                                      <span key={index} className={className}>
                                          {startCase(item?.displayValue)}
                                          {index !== cohorts.length - 1 && ' , '}
                                      </span>
                                  );
                              })}
                    </Typography>
                );
            },
            width: '22rem',
        },
        {
            id: 'lastTriggered',
            name: (
                <Typography variant="body2" color="text-black">
                    {FLOW_HEADER_TEXTS[2]}
                </Typography>
            ),

            cell: (row: FlowSpecDto) => (
                <Typography variant="body5" color="text-high-emphasis">
                    {row.activity?.lastTriggered ?? 'N/A'}
                </Typography>
            ),
            width: '15rem',
        },
        {
            id: 'activity.activeCount',
            name: (
                <div className="w-100 text-center">
                    <Typography variant="body2" color="text-black">
                        {FLOW_HEADER_TEXTS[3]}
                    </Typography>
                </div>
            ),
            center: true,

            cell: (row: FlowSpecDto) => (
                <MdLink
                    className="engagements-accordion__link"
                    size={24}
                    onClick={() => handleShowActiveEngagementModal(row.id, 'active')}
                    data-testid="active-engagement-link"
                />
            ),
            width: '15rem',
        },
    ];
};

interface EngagementsAccordionProps {
    findingData: FindingDto;
}

const EngagementsAccordion = ({ findingData }: EngagementsAccordionProps) => {
    const [showEngagementsModal, setShowEngagementsModal] = useState(false);
    const [modalVariant, setEngagementModalVariant] = useState<EngagementModalVariant>('active');
    const [selectedId, setSelectedId] = useState<string>('');
    const flowSpecTransformer = (dataItem: Page<FlowSpecDto>) => {
        return dataItem.data;
    };
    const { data: fetchFlowSpecs } = useApiObjectData(
        'flowSpecs',
        () => getGridData('flowSpecs', { limit: 100 }, '_summary'),
        flowSpecTransformer,
        {
            limit: 100,
        },
    );

    const matchingFlowSpecs = useMemo(() => {
        if (findingData && !_.isEmpty(fetchFlowSpecs)) {
            return fetchFlowSpecs
                ?.filter((fs) => fs.status !== FlowSpecStatusKind.PAUSED)
                .filter((fs) =>
                    // eslint-disable-next-line max-nested-callbacks
                    fs.triggerFilter.trigger?.some((t) => findingData?.kind === (t.eventType as FindingKind)),
                )
                .filter((fs) =>
                    // eslint-disable-next-line max-nested-callbacks
                    fs.filter.cohorts.map((cohort) => cohort?.id).includes(findingData?.uid ?? undefined),
                );
        }
        return [];
    }, [findingData, fetchFlowSpecs]);

    const handleShowActiveEngagementsModal = (flowSpecId: string, modalVariant: EngagementModalVariant = 'all') => {
        setSelectedId(matchingFlowSpecs[0].id);
        setEngagementModalVariant(modalVariant);
        setShowEngagementsModal(true);
    };
    const handleCloseActiveEngagementsModal = () => setShowEngagementsModal(false);

    return (
        <>
            <Table
                operation="_summary"
                columns={ENGAGEMENT_FLOW_TABLE_HEADER(handleShowActiveEngagementsModal)}
                rowsPerPageOptions={FINDINGS_TABLE_PAGINATION}
                showSubHeader={false}
                tableBodyHeight={'calc(100% - 3.625rem)'}
                defaultSortId="name"
                cacheKey={'flowSpecs'}
                transformer={(a) => a}
                updateUrl={true}
                filter={{ id: matchingFlowSpecs.map((fs) => fs.id)[0] }}
                data={matchingFlowSpecs}
            />
            <EngagementsModal
                variant={modalVariant}
                show={showEngagementsModal}
                onClose={handleCloseActiveEngagementsModal}
                specId={selectedId}
                filterCriteria={{ fid: findingData?.id }}
            />
        </>
    );
};

export default EngagementsAccordion;
