import { BACK, TENANT_SELECTION } from 'Core-utils/constants/constants';
import { Typography } from 'Components/atoms';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import './styles.scss';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch, RootState } from 'Rdx/store';
import { AMP_SETTINGS, KIND, TenantDto } from '@ampsec/platform-client';
import {
    exchangeTokenForAnotherTenant,
    getInstalledConnectors,
    getProfile,
    getTenantSettings,
    getTenantsForCurrentUser,
} from 'Apis/library';
import { cacheTenantData, initializeApi } from 'Src/Api';
import './styles.scss';
import { clearSWRCache } from 'Core-utils/helpers/helpers';
import { useSWRConfig } from 'swr';
import { ORG_DASHBOARD_ROUTE, POLICIES_ROUTE } from 'Core-utils/routes';
import classnames from 'classnames';
import { IoArrowBackOutline } from '@react-icons/all-files/io5/IoArrowBackOutline';
import _ from 'lodash';
import { useApiObjectData } from 'Hooks/useApiObjectData';
import PageErrorHandlerAndLoaderWrapper from 'Wrappers/PageErrorHandlerAndLoaderWrapper';
import { incrementLoginMetric } from 'Core-utils/helpers/SentryHelpers';

const TenantSelectionPage = () => {
    const { data: tenants, isLoading } = useApiObjectData<TenantDto[], TenantDto[]>(
        KIND.TENANTS,
        getTenantsForCurrentUser,
        (a) => a,
        {
            sort: { displayValue: 'ASC' },
        },
    );
    const TenantSelection = () => {
        const navigate = useNavigate();
        const { selectedTenant, user } = useSelector((state: RootState) => state.auth);
        const dispatch = useDispatch<Dispatch>();
        const { cache } = useSWRConfig();

        const getAmpSettingValue = (settingKey: string, tenantSettings: any): any => {
            if (!settingKey || (tenantSettings && _.isEmpty(tenantSettings))) {
                return true;
            }
            return (tenantSettings as any)[settingKey]?.value;
        };

        const handleTenantSelection = async (tenant: TenantDto) => {
            clearSWRCache(cache);
            const token = await exchangeTokenForAnotherTenant(tenant.id);
            dispatch.auth.setSelectedTenants(tenant);
            dispatch.auth.setAuthToken(token);
            const agent = await getProfile(token);
            const me = await agent.json();
            dispatch.auth.setUser(me);
            dispatch.orgModel.setOrg('ALL');
            dispatch.findingsModel.setFindings([]);
            initializeApi(token);
            getTenantSettings().then((res) => {
                const overrideFlag = (res as any)?.['fe.pills.featureFlag.override']?.value;
                if (overrideFlag) {
                    dispatch.featureModel.setDevFeatures(true);
                } else if (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test') {
                    dispatch.featureModel.setDevFeatures(true);
                } else {
                    dispatch.featureModel.setDevFeatures(false);
                }
                dispatch.featureModel.setBanner(!getAmpSettingValue(AMP_SETTINGS.tenant.NOTIFICATIONS_ENABLED.id, res));
            });
            incrementLoginMetric(tenant.displayValue, tenant.id, user.email!);
            getInstalledConnectors({ limit: 1 }).then((res) => {
                _.isEmpty(res) ? navigate(POLICIES_ROUTE) : navigate(ORG_DASHBOARD_ROUTE);
            });
            await cacheTenantData();
        };

        const handleBackButtonClick = () => {
            navigate(-1);
        };

        return (
            <div className="tenant-selection-page bg-structural-BG d-flex justify-content-center align-items-center">
                <div className="tenant-selection-page__tenant-container bg-structural-CardBG">
                    {!_.isEmpty(selectedTenant) && (
                        <div
                            className="d-flex gap-2 tenant-selection-page__back-button"
                            onClick={handleBackButtonClick}
                        >
                            <IoArrowBackOutline className="text-text-high-emphasis" />
                            <Typography variant="body5" color="text-high-emphasis" style={{ marginBottom: '0%' }}>
                                {BACK}
                            </Typography>
                        </div>
                    )}
                    <div
                        className="d-flex flex-column align-items-center justify-content-center gap-4"
                        data-testid="tenant-selection"
                    >
                        <div className="d-flex flex-column align-items-center">
                            <Typography variant="body11" color="text-high-emphasis">
                                {TENANT_SELECTION[0]}
                            </Typography>
                            <Typography variant="subtitle3" color="text-medium-emphasis">
                                {TENANT_SELECTION[1]}
                            </Typography>
                        </div>
                        <div className="tenant-selection-page__button-container d-flex flex-column justify-content-center align-items-center">
                            {tenants.map((tenant: TenantDto) => {
                                return (
                                    <Button
                                        key={tenant.id}
                                        onClick={() => {
                                            handleTenantSelection(tenant);
                                        }}
                                        className={classnames('btn my-2 text-uppercase', {
                                            'bg-primary-500': tenant.id === selectedTenant?.id,
                                        })}
                                    >
                                        <Typography variant="subtitle4" color="text-high-emphasis">
                                            {tenant.displayValue}
                                        </Typography>
                                    </Button>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div className="tenant-selection-page">
            <PageErrorHandlerAndLoaderWrapper
                error={undefined}
                isEmpty={_.isEmpty(tenants)}
                render={() => TenantSelection()}
                isLoading={isLoading}
            />
        </div>
    );
};

export default TenantSelectionPage;
