import { getGridData } from 'Apis/library';
import { Table } from '../Table';
import { AssetDto, FilterCriteria, Page } from '@ampsec/platform-client';
import { Typography } from 'Components/atoms';
import { USER_TABLE_ROWS_PER_PAGE_OPTIONS } from 'Core-utils/constants/constants';
import { useApiObjectData } from 'Hooks/useApiObjectData';
import { TableColumn } from 'react-data-table-component';
import './styles.scss';

interface AssetsGridProps {
    filter?: FilterCriteria;
}

const columns: TableColumn<AssetDto>[] = [
    {
        id: 'displayName',
        name: (
            <div className="w-100 text-center">
                <Typography variant="body2">Devices</Typography>
            </div>
        ),
        center: false,
        width: '19rem',
        wrap: true,
        cell: (row: AssetDto) => {
            return (
                <div className="w-100 text-center">
                    <Typography variant="body2">{row.displayValue}</Typography>
                </div>
            );
        },
    },
    {
        id: 'HostName',
        name: (
            <div className="w-100 text-center">
                <Typography variant="body2">Host Name</Typography>
            </div>
        ),
        center: false,
        wrap: true,
        cell: (row: AssetDto) => {
            return (
                <div className="w-100 text-center">
                    <Typography variant="body2">{row.extKeys?.hostname}</Typography>
                </div>
            );
        },
    },
    {
        id: 'Serial Number',
        name: (
            <div className="w-100 text-center">
                <Typography variant="body2">Serial Number</Typography>
            </div>
        ),
        center: false,
        wrap: true,
        cell: (row: AssetDto) => {
            return (
                <div className="w-100 text-center">
                    <Typography variant="body2">{row.extKeys?.sn}</Typography>
                </div>
            );
        },
    },
    {
        id: 'lastActivityTime',
        name: (
            <div className="w-100 text-center">
                <Typography variant="body2">last Activity Time</Typography>
            </div>
        ),
        center: false,
        wrap: true,
        cell: (row: AssetDto) => {
            return (
                <div className="w-100 text-center">
                    <Typography variant="body2">{row.lastActivityTime}</Typography>
                </div>
            );
        },
    },
];

const AssetsGrid: React.FC<AssetsGridProps> = ({ filter }) => {
    const { data: assetData } = useApiObjectData<Page<AssetDto>, Page<AssetDto>>(
        'asset',
        getGridData,
        (a) => a,
        filter,
    );

    const tableBodyHeight = assetData?.data?.length > 3 ? '10rem' : undefined;
    return (
        <div className="asset-grid">
            <Table
                data={assetData?.data || []}
                cacheKey={'asset'}
                columns={columns}
                rowsPerPageOptions={USER_TABLE_ROWS_PER_PAGE_OPTIONS}
                transformer={(a) => a}
                filter={filter}
                showSubHeader={false}
                pagination={false}
                tableBodyHeight={tableBodyHeight}
            />
        </div>
    );
};

export default AssetsGrid;
