import { FindingsExpandableComponent } from 'Components/organisms';
import { EngagementChannelExpandable } from 'Components/organisms';
import { UserCohort } from 'Components/organisms';
import { MenuItem, Row } from 'Core-utils/types';
import './styles.scss';

interface ExpandableEngagementListProps {
    selectedFindingsOptions: {
        id: string;
        value: string;
        cid: string;
        eventType: string;
        icon: string;
    }[];
    findingsDropdownOptions: MenuItem[];
    onFindingsDropdownChange: (targetId: string, itemId: string, eventKey: string | null) => void;
    onFindingsDeleteSelectedOption: (indexToDelete: string) => void;
    onFindingsSearchChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    findingsSearchQuery: string;
    userCohortRows: Row[];
    setUserCohortRows: React.Dispatch<React.SetStateAction<Row[]>>;
    selectedEngagementOptions: { id: string; icon: string; value: string }[];
    setSelectedEngagementOptions: React.Dispatch<
        React.SetStateAction<
            {
                id: string;
                icon: string;
                value: string;
            }[]
        >
    >;
    dropdownEngagementOptions: MenuItem[];
}

const ExpandableEngagementList = ({
    selectedFindingsOptions,
    userCohortRows,
    setUserCohortRows,
    selectedEngagementOptions,
    setSelectedEngagementOptions,
    onFindingsDropdownChange,
    onFindingsDeleteSelectedOption,
    onFindingsSearchChange,
    findingsSearchQuery,
    findingsDropdownOptions,
    dropdownEngagementOptions,
}: ExpandableEngagementListProps) => {
    return (
        <div className="expandable-list" data-testid="expandable-list">
            <FindingsExpandableComponent
                selectedOptions={selectedFindingsOptions}
                onFindingsDropdownChange={onFindingsDropdownChange}
                onFindingsDeleteSelectedOption={onFindingsDeleteSelectedOption}
                onFindingsSearchChange={onFindingsSearchChange}
                findingsSearchQuery={findingsSearchQuery}
                findingsDropdownOptions={findingsDropdownOptions}
            />
            <UserCohort rows={userCohortRows} setRows={setUserCohortRows} />
            <EngagementChannelExpandable
                selectedOptions={selectedEngagementOptions}
                setSelectedOptions={setSelectedEngagementOptions}
                dropdownOptions={dropdownEngagementOptions}
            />
        </div>
    );
};

export default ExpandableEngagementList;
