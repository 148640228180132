import React from 'react';
import { Button, Image } from 'react-bootstrap';
import { CustomTooltip } from 'Components/atoms';
import arch from 'Assets/icons/arch.svg';
import clock from 'Assets/icons/clock.svg';
import departmentIcon from 'Assets/icons/department.svg';
import human from 'Assets/icons/person.svg';
import { IconWithTypography } from 'Components/molecules';
import { Typography } from 'Components/atoms';
import { USERHEADER } from 'Core-utils/constants/constants';
import { Chip } from 'Components/atoms';
import Profile from 'Assets/icons/profile.svg';
import { Avatar } from 'Components/atoms';
import Arrow from 'Assets/icons/arrow.svg';
import BadgeIcon from 'Assets/icons/badge.svg';
import { MdEmail } from '@react-icons/all-files/md/MdEmail';
import { dateCalculation } from 'Core-utils/helpers/helpers';
import './styles.scss';

interface UserHeaderProps {
    userId?: string;
    imageUrl: string;
    firstName: string;
    lastName: string;
    department: string;
    manager: string;
    organization: string;
    position: string;
    startDate: string;
    chipLabel: string;
    email: string;
    designation: string;
}
interface UserContentHeaderProps {
    imageUrl: string;
    firstName: string;
    lastName: string;
    position: string;
    chipLabel: string;
}
const UserContentHeader = ({ imageUrl, firstName, lastName, chipLabel }: UserContentHeaderProps) => {
    return (
        <div className="user-header__column d-flex">
            <div className="d-flex flex-row align-items-center justify-content-center">
                <div className="user-header__avatar-icon">
                    <Avatar
                        height="60px"
                        width="60px"
                        image={imageUrl}
                        variant="avatar"
                        name={`${firstName} ${lastName}`}
                    />
                </div>
                <div className="user-header__column d-flex flex-column justify-content-center ">
                    <div className="user-header__header-container d-flex mb-1">
                        <CustomTooltip label={`${firstName} ${lastName}` ?? '-'}>
                            <Typography variant="h4" color="text-high-emphasis" className="user-header__typography">
                                {`${firstName} ${lastName}` ?? '-'}
                            </Typography>
                        </CustomTooltip>
                    </div>
                    <div className="d-flex user-header">
                        <div className="user-header__chip-style">
                            <Chip value={chipLabel} variant="resolved_issue_variant" />
                        </div>
                        <div className="visibility-hidden">
                            <Chip value="Employee" image={Profile} variant="user_score" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
interface startDateOrganizationProp {
    startDate: string;
    organization: string;
}
const UserHeaderContentValues = ({ organization, startDate }: startDateOrganizationProp) => {
    const userHeaderValues = [
        {
            icon: <Image src={arch} className="user-header__icon" />,
            labelIndex: 2,
            value: organization || '-',
        },
        {
            icon: <Image src={clock} className="user-header__icon" />,
            labelIndex: 3,
            value: dateCalculation(new Date(startDate), new Date()) || '-',
        },
    ];

    return (
        <div className="user-header__column user-header__value-container d-flex flex-column">
            {userHeaderValues.map((userInfo, index) => (
                <div key={index} className="user-header__item d-flex flex-row align-items-center">
                    {renderUserInfo(userInfo.icon, userInfo.labelIndex, userInfo.value)}
                </div>
            ))}
        </div>
    );
};

const ButtonComponent = () => {
    return (
        <div className="user-header__column user-header__button-container d-flex flex-row justify-content-end align-items-center">
            <Button className="btn" disabled>
                <IconWithTypography
                    endIcon={<Image src={Arrow} alt="dropdown-arrow" className="user-header__dropdown-icon" />}
                    isRow={true}
                    label="ACTION"
                    labelColor="structural-selected"
                    labelVariant="body2"
                />
            </Button>
        </div>
    );
};

const renderUserInfo = (icon: React.ReactNode, labelIndex: number, value: string) => {
    return (
        <div className="user-header__item  d-flex flex-row align-items-center">
            <div className="user-header__styled-category d-flex justify-content-between">
                <IconWithTypography
                    isRow={true}
                    startIcon={icon}
                    label={USERHEADER[labelIndex]}
                    labelColor="text-medium-emphasis"
                    labelVariant="body3"
                />
                <div className="colon">
                    <Typography variant="body3" color="text-medium-emphasis" children=":" />
                </div>
            </div>
            <CustomTooltip label={value ?? ''}>
                <Typography
                    variant="body2"
                    style={{ width: '10.5rem' }}
                    className="text-truncate"
                    color="text-medium-emphasis"
                    children={value}
                />
            </CustomTooltip>
        </div>
    );
};

interface departmentManagerProp {
    department: string;
    manager: string;
}
const UserDepartmentValues = ({ department, manager }: departmentManagerProp) => {
    const departmentValues = [
        {
            icon: <Image src={departmentIcon} className="user-header__icon" />,
            labelIndex: 4,
            value: department || '-',
        },
        {
            icon: <Image src={human} className="user-header__icon" />,
            labelIndex: 5,
            value: manager || '-',
        },
    ];
    return (
        <div className="user-header__column user-header__value-container d-flex flex-column">
            {departmentValues.map((userInfo, index) => (
                <div key={index} className="user-header__item d-flex flex-row align-items-center">
                    {renderUserInfo(userInfo.icon, userInfo.labelIndex, userInfo.value)}
                </div>
            ))}
        </div>
    );
};

type UserInformationProp = {
    email?: string;
    designation?: string;
};

const UserInformation = ({ email, designation }: UserInformationProp) => {
    const departmentValues = [
        {
            icon: <Image src={BadgeIcon} className="user-header__icon" />,
            labelIndex: 0,
            value: designation ?? '-',
        },
        {
            icon: <MdEmail className="text-structural-stroke-100" size={19} />,
            labelIndex: 1,
            value: email ?? '-',
        },
    ];
    return (
        <div className="user-header__column user-header__value-container d-flex flex-column">
            {departmentValues.map((userInfo, index) => (
                <div key={index} className="user-header__item d-flex flex-row align-items-center">
                    {renderUserInfo(userInfo.icon, userInfo.labelIndex, userInfo.value)}
                </div>
            ))}
        </div>
    );
};

const UserHeader = (props: UserHeaderProps) => {
    const {
        imageUrl,
        firstName,
        lastName,
        department,
        manager,
        position,
        organization,
        startDate,
        chipLabel,
        email,
        designation,
    } = props;
    return (
        <div className="user-header__container d-flex bg-text-black" data-testid="user_header">
            <UserContentHeader
                imageUrl={imageUrl}
                firstName={firstName}
                lastName={lastName}
                position={position}
                chipLabel={chipLabel}
            />
            <div className="user-header__divider bg-structural-stroke-100" />
            <UserInformation email={email} designation={designation} />
            <div className="user-header__divider bg-structural-stroke-100" />
            <UserDepartmentValues department={department} manager={manager} />
            <div className="user-header__divider bg-structural-stroke-100" />
            <UserHeaderContentValues startDate={startDate} organization={organization} />
            <ButtonComponent />
        </div>
    );
};

export default UserHeader;
