import { ChangeEvent, useContext, useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import { Card } from 'react-bootstrap';
import { Accordion } from 'Components/atoms';
import { DynamicDropdown, ToggleTextPanel } from 'Components/molecules';
import { ConfirmationModal } from 'Components/molecules';
import { SHARE_SECURITY_POSTURE_DATA } from './shareSecurityPostureData';
import {
    COMPANY_PARAMETERS,
    SETTINGS,
    BUTTON_LABEL_MAP,
    MODAL_CONTENT_MAP,
    SUCCESS_VARIANT,
    CONNECTOR_SETTINGS_SAVED,
    HEALTH_SCORE_CONFIGURATION,
    HEALTH_SCORE_CONFIGURATION_TABS,
    USER_MANAGEMENT,
    DISABLE_NOTIFICATIONS,
    DISABLE_NOTIFICATIONS_CAPTION,
} from 'Core-utils/constants/constants';
import { AMP_SETTINGS, AuthRole } from '@ampsec/platform-client';
import { useApiData } from 'Hooks/useApiData';
import { createSetting, getTenantSettings, updateSettings } from 'Apis/library';
import { AmpSettingDto, AmpSettingUpsertDto } from '@ampsec/platform-client';
import { Dispatch, RootState } from 'Rdx/store';
import { useDispatch, useSelector } from 'react-redux';
import PageErrorHandlerAndLoaderWrapper from 'Wrappers/PageErrorHandlerAndLoaderWrapper';
import { ToastsContext } from 'Rdx/contexts/ToastsContext';
import { ConfigurationTabs } from 'Components/organisms';
import HighRiskCohortConfigurationTable from './HighRiskCohortConfigurationTable';
import HealthScoreConfigurationTable from './HealthScoreConfigurationTable';
import { Typography } from 'Components/atoms';
import { CustomTooltip } from 'Components/atoms';
import { IoMdInformationCircleOutline } from '@react-icons/all-files/io/IoMdInformationCircleOutline';
import { useI18n } from 'Hooks/useI18n';
import { WeekDayPicker } from 'Components/molecules/WeekDayPicker';
import EventTimeZoneSetting from 'Components/molecules/EventTimeZoneSetting/EventTimeZoneSetting';
import { formattedTimezoneOptions, generateDayOptions, generateTimeSlots } from 'Core-utils/helpers/helpers';
import './styles.scss';
import UserManagementGrid from './UserManagementGrid';

export type TenantAmpSetting = '' | keyof typeof AMP_SETTINGS.tenant;

const DEFAULT_ACTIVE_KEYS = ['0', '1', '2'];

const SettingConfirmationModal = ({
    content,
    getAmpSettingValue,
    savingSettings,
    settingKey,
    showConfirmationModal,
    title,
    onCallback,
    onClose,
    onConfirm,
    onError,
}: {
    content?: string;
    getAmpSettingValue: (settingKey: string) => any;
    savingSettings?: boolean;
    settingKey: string;
    showConfirmationModal: boolean;
    title: string;
    onCallback?: () => void;
    onClose?: (newAmpSetting?: AmpSettingDto) => void;
    onConfirm?: () => Promise<AmpSettingDto | undefined>;
    onError?: (error: string) => void;
}) => {
    const handleConfirmAction = () => {
        if (settingKey === '') {
            return;
        }

        onConfirm &&
            onConfirm()
                .then((data) => onClose && onClose(data))
                .catch((error) => onError && onError(error.message))
                .finally(() => onCallback && onCallback());
    };

    const renderPrimaryButtonLabel = () => {
        return settingKey === 'notifications.enabled'
            ? BUTTON_LABEL_MAP.NOTIFICATIONS_ENABLED?.[
                  getAmpSettingValue(AMP_SETTINGS.tenant.NOTIFICATIONS_ENABLED.id)?.toString()
              ] || ''
            : 'Yes, Save it';
    };

    const handleExit = () => onClose && onClose(undefined);

    return (
        <ConfirmationModal
            cancelButtonDisabled={savingSettings}
            modalTitle={title}
            modalBodyTitle={content}
            onPrimaryButtonClick={handleConfirmAction}
            primaryButtonLabel={renderPrimaryButtonLabel()}
            primaryButtonDisabled={savingSettings}
            show={showConfirmationModal}
            onClose={handleExit}
            buttonVariant="primary"
        />
    );
};

const SettingsPage = () => {
    const { t } = useI18n();
    const { data: tenantSettings, isLoading, refresh: refreshTenantSettings, error: settingsError } = useApiData<
        { [key: string]: AmpSettingDto },
        { [key: string]: AmpSettingDto }
    >('TENANT_SETTINGS', getTenantSettings, (a) => a);
    const [error, setError] = useState<string>('');
    const enableBanner = useSelector((state: RootState) => state.featureModel.enableBanner);
    const [settingKey, setSettingKey] = useState<string | null>();
    const [savingSettings, setSavingSettings] = useState<boolean>(false);
    const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);
    const { addToast } = useContext(ToastsContext);
    const [_initialSettingsValues, setInitialSettingsValues] = useState<{ [key: string]: AmpSettingDto } | null>();
    const [userSettingInput, setUserSettingInput] = useState<{ [key: string]: string | boolean }>({});
    const [userData, setUserData] = useState(SHARE_SECURITY_POSTURE_DATA);
    const [showModal, setShowModal] = useState({
        accessUser: false,
        removeUser: false,
    });

    const dispatch = useDispatch<Dispatch>();
    const [assetArchivedThreshold, setAssetArchivedThreshold] = useState<string>(
        AMP_SETTINGS.tenant.ASSET_ARCHIVED_THRESHOLD.defaultValue,
    );
    const [assetLastSeenThreshold, setAssetLastSeenThreshold] = useState<string>(
        AMP_SETTINGS.tenant.ASSET_LAST_SEEN_THRESHOLD.defaultValue,
    );
    const [selectedDelay, setSelectedDelay] = useState<string>(AMP_SETTINGS.tenant.WELCOME_MESSAGE_DELAY.defaultValue);

    const [selectedWeekdays, setSelectedWeekdays] = useState<number[]>(
        AMP_SETTINGS.tenant.ACTIVE_ENGAGEMENT_WINDOW_SELECTED_WEEKDAYS?.defaultValue,
    );
    const [selectedTimezone, setSelectedTimezone] = useState<string>(
        AMP_SETTINGS.tenant.ACTIVE_ENGAGEMENT_WINDOW_TIMEZONE?.defaultValue,
    );
    const [startTime, setStartTime] = useState(AMP_SETTINGS.tenant.ACTIVE_ENGAGEMENT_WINDOW_START_TIME?.defaultValue);
    const [endTime, setEndTime] = useState(AMP_SETTINGS.tenant.ACTIVE_ENGAGEMENT_WINDOW_END_TIME?.defaultValue);
    const welcomeMessageDropdownOptions = [
        'Immediate',
        '1 minutes',
        '2 minutes',
        '3 minutes',
        '4 minutes',
        '5 minutes',
        '6 minutes',
        '7 minutes',
        '8 minutes',
        '9 minutes',
        '10 minutes',
    ];

    const getAmpSettingValue = (settingKey: string): any => {
        if (!settingKey || (tenantSettings && _.isEmpty(tenantSettings))) {
            return true;
        }
        return tenantSettings && (tenantSettings as any)[settingKey]?.value;
    };

    const handleToggle = (type: string, value: boolean) => {
        setShowConfirmationModal((visible) => !visible);
        setSettingKey(type);
        setUserSettingInput((current) => ({ ...current, [type]: value }));
    };
    const _handleSaveClick = (setting: string) => () => {
        setSettingKey(setting);
        setShowConfirmationModal(true);
    };

    const handleMoreActions = (item: string, id: string) => {
        // TODO: Need to Integrate with apis
        if (item === 'REMOVE USER') {
            const modalObj = {
                ...showModal,
                accessUser: false,
                removeUser: true,
            };
            setShowModal(modalObj);

            const updatedUserData = userData.filter((user) => user.id.toString() !== id);
            setUserData(updatedUserData);
        } else {
            const newStatus = item === 'ACTIVATE USER' ? 'ACTIVE' : 'SUSPENDED';
            const updatedUserData = userData.map((user) => {
                if (user.id.toString() === id) {
                    user.status = newStatus;
                }
                return user;
            });
            setUserData(updatedUserData);
        }
    };

    const handleUpdateAccess = (id: number, value: string) => {
        const modalObj = {
            ...showModal,
            accessUser: true,
            removeUser: false,
        };
        setShowModal(modalObj);
        const updatedUserData = userData.map((user) => {
            if (user.id === id) {
                user.access = value;
            }
            return user;
        });
        setUserData(updatedUserData);
    };

    const handleCloseModal = () => {
        const modalObj = {
            ...showModal,
            accessUser: false,
            removeUser: false,
        };
        setShowModal(modalObj);
    };

    const handleAccessChange = () => {
        handleUpdateAccess;
        const modalObj = {
            ...showModal,
            accessUser: false,
            removeUser: false,
        };
        setShowModal(modalObj);
    };

    const handleRemoveUser = () => {
        handleMoreActions;
        const modalObj = {
            ...showModal,
            accessUser: false,
            removeUser: false,
        };
        setShowModal(modalObj);
    };

    const handleTenantList = () => {
        if (isLoading || !tenantSettings || Object.keys(tenantSettings).length === 0) {
            return;
        }
        if (!Object.keys(tenantSettings)?.includes(AMP_SETTINGS.tenant.NOTIFICATIONS_ENABLED.id)) {
            const notificationsEnabledSetting = (tenantSettings as any)[AMP_SETTINGS.tenant.NOTIFICATIONS_ENABLED.id];
            if (notificationsEnabledSetting === undefined || notificationsEnabledSetting === null) {
                createSetting(
                    AMP_SETTINGS.tenant.NOTIFICATIONS_ENABLED.id,
                    AMP_SETTINGS.tenant.NOTIFICATIONS_ENABLED.defaultValue,
                ).catch((error) => {
                    setError(error.message);
                });
            }
        }
    };

    const _handleSettingChange = (settingKey: string) => (value: string) => {
        setUserSettingInput((current) => ({ ...current, [settingKey]: value }));
    };

    const handleToggleNotificationMode = (ev: ChangeEvent<HTMLInputElement>) => {
        handleToggle(AMP_SETTINGS.tenant.NOTIFICATIONS_ENABLED.id, !ev.target.checked);
    };
    const _handleToggleSlackQaAllMessages = (ev: ChangeEvent<HTMLInputElement>) =>
        handleToggle(AMP_SETTINGS.tenant.SLACK_QA_ALL_MESSAGES.id, ev.target.checked);

    const handleModalClose = (newAmpSetting?: AmpSettingDto) => {
        if (newAmpSetting) {
            addToast({
                severity: SUCCESS_VARIANT,
                body: `${CONNECTOR_SETTINGS_SAVED}`,
            });
        }
        setSettingKey(null);
        setShowConfirmationModal(false);
    };
    const handleModalError = (error: string) => setError(error);

    const getAmpSettingId = (settingKey: string) => {
        if (!settingKey || tenantSettings === undefined || tenantSettings === null) {
            return '';
        }
        return ((tenantSettings as any)[settingKey]?.id as string) || '';
    };

    const transformSettingValue = (settingKey: string): any => {
        if (!settingKey || !userSettingInput) {
            return '';
        }
        switch (settingKey) {
            case AMP_SETTINGS.tenant.SLACK_QA_ALL_MESSAGES.id:
            case AMP_SETTINGS.tenant.NOTIFICATIONS_ENABLED.id:
                return userSettingInput[settingKey];
            case AMP_SETTINGS.tenant.DEFAULT_NOTIFICATION_CONNECTOR_ID.id:
                return {
                    cid: userSettingInput[settingKey],
                    displayValue: 'Default Notification Connector ID',
                };
            default:
                return getAmpSettingValue(settingKey);
        }
    };

    const handleConfirmModal = () => {
        setSavingSettings(true);
        if (!settingKey) {
            throw new Error('No setting was changed');
        }
        const ampSettingId = getAmpSettingId(settingKey);

        const existingSetting = Object.keys(tenantSettings as any).find((setting) => setting === settingKey);

        // If the setting already exists, update it. Otherwise, create it.
        if (existingSetting !== undefined) {
            return updateSettings(ampSettingId, {
                id: ampSettingId,
                key: settingKey,
                value: transformSettingValue(settingKey),
            } as AmpSettingUpsertDto);
        } else {
            return createSetting(settingKey, transformSettingValue(settingKey));
        }
    };

    const handleModalCallback = () => {
        refreshTenantSettings();
        setSavingSettings(false);
    };

    const modalContent = () => {
        switch (settingKey) {
            case AMP_SETTINGS.tenant.NOTIFICATIONS_ENABLED.id:
                return MODAL_CONTENT_MAP.NOTIFICATIONS_ENABLED?.[
                    getAmpSettingValue(AMP_SETTINGS.tenant.NOTIFICATIONS_ENABLED.id)?.toString()
                ];
            case AMP_SETTINGS.tenant.SLACK_QA_ALL_MESSAGES.id:
            case AMP_SETTINGS.tenant.DEFAULT_NOTIFICATION_CONNECTOR_ID.id:
                return 'Are you sure you want to save this setting?';
            default:
                return `UNKNOWN SETTING KEY: ${settingKey}`;
        }
    };

    const modalTitle = () => {
        switch (settingKey) {
            case AMP_SETTINGS.tenant.NOTIFICATIONS_ENABLED.id:
                return 'Disable Notifications';
            case AMP_SETTINGS.tenant.SLACK_QA_ALL_MESSAGES.id:
                return 'Send all engagements to Slack';
            case AMP_SETTINGS.tenant.DEFAULT_NOTIFICATION_CONNECTOR_ID.id:
                return 'Default Connector';
            default:
                return `UNKNOWN SETTING KEY: ${settingKey}`;
        }
    };

    useEffect(() => {
        handleTenantList();
    }, [tenantSettings, isLoading]);

    useEffect(() => {
        if (!tenantSettings) {
            return;
        }

        const tempInitialSettingsValues: { [key: string]: AmpSettingDto } = {};
        Object.keys(tenantSettings as { [key: string]: AmpSettingDto }).forEach((setting) => {
            tempInitialSettingsValues[setting] = (tenantSettings as any)[setting];
        });
        setInitialSettingsValues(tempInitialSettingsValues);
        dispatch.featureModel.setBanner(!error && !getAmpSettingValue(AMP_SETTINGS.tenant.NOTIFICATIONS_ENABLED.id));
    }, [tenantSettings]);

    const SettingsContent = () => {
        const auth = useSelector((state: RootState) => state.auth);
        const AmpSettingSetter = (settingId: string, value: any) => {
            switch (settingId) {
                case AMP_SETTINGS.tenant.ASSET_ARCHIVED_THRESHOLD.id:
                    setAssetArchivedThreshold(value as string);
                    break;
                case AMP_SETTINGS.tenant.ASSET_LAST_SEEN_THRESHOLD.id:
                    setAssetLastSeenThreshold(value as string);
                    break;
                case AMP_SETTINGS.tenant.WELCOME_MESSAGE_DELAY.id:
                    setSelectedDelay(value as string);
                    break;
                case AMP_SETTINGS.tenant.ACTIVE_ENGAGEMENT_WINDOW_SELECTED_WEEKDAYS.id:
                    setSelectedWeekdays(value as number[]);
                    break;
                case AMP_SETTINGS.tenant.ACTIVE_ENGAGEMENT_WINDOW_TIMEZONE.id:
                    setSelectedTimezone(value as string);
                    break;
                case AMP_SETTINGS.tenant.ACTIVE_ENGAGEMENT_WINDOW_START_TIME.id:
                    setStartTime(value as string);
                    break;
                case AMP_SETTINGS.tenant.ACTIVE_ENGAGEMENT_WINDOW_END_TIME.id:
                    setEndTime(value as string);
                    break;
                default:
                    break;
            }
        };

        const updateAmpSetting = (settingId: string, value: any) => {
            const modifiedValue =
                settingId === AMP_SETTINGS.tenant.ACTIVE_ENGAGEMENT_WINDOW_TIMEZONE.id
                    ? value.replace(/\(GMT[+-]\d{2}:\d{2}\)\s/, '')
                    : value;
            if (settingId) {
                updateSettings(getAmpSettingId(settingId), {
                    id: getAmpSettingId(settingId),
                    key: settingId,
                    value: modifiedValue,
                } as AmpSettingUpsertDto)
                    .then(() => {
                        AmpSettingSetter(settingId, value);
                    })
                    .catch((error) => {
                        setError(error.message);
                    });
            }
        };

        const handleAssetArchivedThresholdChange = (option: string) => {
            updateAmpSetting(AMP_SETTINGS.tenant.ASSET_ARCHIVED_THRESHOLD.id, option);
        };

        const handleAssetLastSeenThresholdChange = (option: string) => {
            updateAmpSetting(AMP_SETTINGS.tenant.ASSET_LAST_SEEN_THRESHOLD.id, option);
        };

        const handleDelayChange = (option: string) => {
            updateAmpSetting(AMP_SETTINGS.tenant.WELCOME_MESSAGE_DELAY.id, option);
        };

        const handleWeekdayClick = (weekdayId: number) => {
            const updatedSelected = selectedWeekdays.includes(weekdayId)
                ? selectedWeekdays.filter((day) => day !== weekdayId)
                : [...selectedWeekdays, weekdayId];
            updateAmpSetting(AMP_SETTINGS.tenant.ACTIVE_ENGAGEMENT_WINDOW_SELECTED_WEEKDAYS.id, updatedSelected);
        };

        const handleTimezoneChange = (timezone: string) => {
            updateAmpSetting(AMP_SETTINGS.tenant.ACTIVE_ENGAGEMENT_WINDOW_TIMEZONE.id, timezone);
        };

        // TODO: Add a check to validate startTime < endTime
        const handleStartTimeChange = (time: string) => {
            updateAmpSetting(AMP_SETTINGS.tenant.ACTIVE_ENGAGEMENT_WINDOW_START_TIME.id, time);
        };

        const handleEndTimeChange = (time: string) => {
            updateAmpSetting(AMP_SETTINGS.tenant.ACTIVE_ENGAGEMENT_WINDOW_END_TIME.id, time);
        };

        const createAmpSetting = (settingId: string, value: any) => {
            if (tenantSettings && !Object.keys(tenantSettings)?.includes(settingId)) {
                const setting = (tenantSettings as any)[settingId];
                const modifiedValue =
                    settingId === AMP_SETTINGS.tenant.ACTIVE_ENGAGEMENT_WINDOW_TIMEZONE.id
                        ? value.replace(/\(GMT[+-]\d{2}:\d{2}\)\s/, '')
                        : value;
                if (setting === undefined || setting === null) {
                    createSetting(settingId, modifiedValue)
                        .then(() => {
                            refreshTenantSettings();
                        })
                        .catch((error) => {
                            setError(error.message);
                        });
                }
            } else {
                const tempValue = (tenantSettings as { [key: string]: AmpSettingDto })?.[settingId]?.value as string;
                const settingValue =
                    settingId === AMP_SETTINGS.tenant.ACTIVE_ENGAGEMENT_WINDOW_TIMEZONE.id
                        ? formattedTimezoneOptions.find((tz) => tz.toLowerCase().includes(tempValue.toLowerCase()))
                        : tempValue;
                AmpSettingSetter(settingId, settingValue);
            }
        };

        useEffect(() => {
            if (isLoading || !tenantSettings || Object.keys(tenantSettings).length === 0) {
                return;
            }
            createAmpSetting(
                AMP_SETTINGS.tenant.ASSET_ARCHIVED_THRESHOLD.id,
                AMP_SETTINGS.tenant.ASSET_ARCHIVED_THRESHOLD.defaultValue,
            );
            createAmpSetting(
                AMP_SETTINGS.tenant.ASSET_LAST_SEEN_THRESHOLD.id,
                AMP_SETTINGS.tenant.ASSET_LAST_SEEN_THRESHOLD.defaultValue,
            );
            createAmpSetting(
                AMP_SETTINGS.tenant.WELCOME_MESSAGE_DELAY.id,
                AMP_SETTINGS.tenant.WELCOME_MESSAGE_DELAY.defaultValue,
            );
            createAmpSetting(
                AMP_SETTINGS.tenant.ACTIVE_ENGAGEMENT_WINDOW_SELECTED_WEEKDAYS.id,
                AMP_SETTINGS.tenant.ACTIVE_ENGAGEMENT_WINDOW_SELECTED_WEEKDAYS.defaultValue,
            );
            createAmpSetting(
                AMP_SETTINGS.tenant.ACTIVE_ENGAGEMENT_WINDOW_TIMEZONE.id,
                AMP_SETTINGS.tenant.ACTIVE_ENGAGEMENT_WINDOW_TIMEZONE.defaultValue,
            );
            createAmpSetting(
                AMP_SETTINGS.tenant.ACTIVE_ENGAGEMENT_WINDOW_START_TIME.id,
                AMP_SETTINGS.tenant.ACTIVE_ENGAGEMENT_WINDOW_START_TIME.defaultValue,
            );
            createAmpSetting(
                AMP_SETTINGS.tenant.ACTIVE_ENGAGEMENT_WINDOW_END_TIME.id,
                AMP_SETTINGS.tenant.ACTIVE_ENGAGEMENT_WINDOW_END_TIME.defaultValue,
            );
        }, [tenantSettings]);

        const dropdownTimeSlotsOptions = generateTimeSlots('12:00 AM', '11:30 PM', 30);
        const CompanyParameters = () => {
            return (
                <div className="d-flex flex-column gap-4">
                    <ToggleTextPanel
                        header={DISABLE_NOTIFICATIONS}
                        subText={DISABLE_NOTIFICATIONS_CAPTION}
                        checked={enableBanner}
                        onToggle={handleToggleNotificationMode}
                    />
                    <div>
                        <Typography variant="body2" color="text-high-emphasis">
                            {t('WELCOME_MESSAGE')}
                        </Typography>
                        <Typography variant="body3" color="text-medium-emphasis">
                            {t('WELCOME_MESSAGE_SUB_TEXT')}
                        </Typography>
                        <div className="settings-page__dropdown-container">
                            <DynamicDropdown
                                variant="default"
                                options={welcomeMessageDropdownOptions}
                                selectedOptions={selectedDelay}
                                onOptionSelected={handleDelayChange}
                            />
                        </div>
                    </div>
                    <div className="d-flex gap-2 align-items-center">
                        <Typography variant="body2" color="text-high-emphasis" className="mb-0">
                            {t('NON_TRIAGE_ENGAGEMENT_ACTIVE_WINDOW')}
                        </Typography>
                        <CustomTooltip label={t('NON_TRIAGE_ACTIVE_WINDOW_TOOLTIP_LABEL')}>
                            <IoMdInformationCircleOutline className="text-text-low-emphasis" size={20} />
                        </CustomTooltip>
                    </div>

                    <div>
                        <Typography variant="body3" color="text-high-emphasis">
                            {t('REPEAT_ON')}
                        </Typography>
                        <WeekDayPicker selectedWeekdays={selectedWeekdays} onWeekdayClick={handleWeekdayClick} />
                    </div>
                    <Typography variant="body3" color="text-high-emphasis" className="d-flex align-items-center mb-0">
                        {t('FROM_START_TIME')} &nbsp;&nbsp;
                        <div className="settings-page__dropdown-container w-30">
                            <DynamicDropdown
                                variant="default"
                                options={dropdownTimeSlotsOptions}
                                selectedOptions={startTime}
                                onOptionSelected={handleStartTimeChange}
                                placeholder="Start Time"
                            />
                        </div>
                        &nbsp;&nbsp;
                        {t('TO_END_TIME')} &nbsp;&nbsp;
                        <div className="settings-page__dropdown-container w-30">
                            <DynamicDropdown
                                variant="default"
                                options={dropdownTimeSlotsOptions}
                                selectedOptions={endTime}
                                onOptionSelected={handleEndTimeChange}
                                placeholder="End Time"
                            />
                        </div>
                        &nbsp;&nbsp;
                    </Typography>

                    <EventTimeZoneSetting selectedTimezone={selectedTimezone} onTimezoneChange={handleTimezoneChange} />
                    <Typography variant="body2" color="text-high-emphasis" className="mb-0">
                        {t('ASSET_MANAGEMENT')}
                    </Typography>
                    <div className="d-flex flex-column gap-2">
                        <div className="d-flex gap-2 align-items-center">
                            <Typography variant="body3" color="text-high-emphasis" className="mb-0">
                                {t('ASSET_LAST_SEEN_THRESHOLD')}
                            </Typography>
                            <CustomTooltip label={t('ASSET_LAST_SEEN_THRESHOLD_TOOLTIP')}>
                                <IoMdInformationCircleOutline className="text-text-low-emphasis" size={20} />
                            </CustomTooltip>
                        </div>
                        <div className="settings-page__dropdown-container">
                            <DynamicDropdown
                                variant="default"
                                options={generateDayOptions()}
                                selectedOptions={assetLastSeenThreshold}
                                onOptionSelected={handleAssetLastSeenThresholdChange}
                            />
                        </div>
                    </div>
                    <div className="d-flex flex-column gap-2">
                        <div className="d-flex gap-2 align-items-center">
                            <Typography variant="body3" color="text-high-emphasis" className="mb-0">
                                {t('ASSET_ARCHIVED_THRESHOLD')}
                            </Typography>
                            <CustomTooltip label={t('ASSET_ARCHIVED_THRESHOLD_TOOLTIP')}>
                                <IoMdInformationCircleOutline className="text-text-low-emphasis" size={20} />
                            </CustomTooltip>
                        </div>
                        <div className="settings-page__dropdown-container">
                            <DynamicDropdown
                                variant="default"
                                options={generateDayOptions()}
                                selectedOptions={assetArchivedThreshold}
                                onOptionSelected={handleAssetArchivedThresholdChange}
                            />
                        </div>
                    </div>
                </div>
            );
        };

        const isHidden = useMemo(() => ((auth.user.role as unknown) as AuthRole) !== AuthRole.OWNER, [auth]);

        return (
            <div className="settings-page d-flex flex-column pt-4 gap-4">
                <Typography variant="body11" color="text-high-emphasis">
                    {SETTINGS}
                </Typography>
                <Card className="bg-text-black p-3 gap-3 d-flex">
                    <Accordion
                        accordionItems={[
                            {
                                key: '0',
                                title: COMPANY_PARAMETERS,
                                isConfigurationRequired: false,
                                eventKey: '0',
                                children: <CompanyParameters />,
                            },
                            {
                                key: '1',
                                title: USER_MANAGEMENT,
                                isConfigurationRequired: false,
                                eventKey: '1',
                                isHidden,
                                children: <UserManagementGrid />,
                            },
                            {
                                key: '2',
                                title: HEALTH_SCORE_CONFIGURATION,
                                isConfigurationRequired: false,
                                eventKey: '2',
                                children: (
                                    <>
                                        <ConfigurationTabs
                                            configTabs={[
                                                {
                                                    eventKey: HEALTH_SCORE_CONFIGURATION_TABS[0],
                                                    title: HEALTH_SCORE_CONFIGURATION_TABS[0],
                                                    content: <HealthScoreConfigurationTable />,
                                                },
                                                {
                                                    eventKey: HEALTH_SCORE_CONFIGURATION_TABS[1],
                                                    title: HEALTH_SCORE_CONFIGURATION_TABS[1],
                                                    content: <HighRiskCohortConfigurationTable />,
                                                },
                                            ]}
                                        />
                                    </>
                                ),
                            },
                        ]}
                        defaultActiveKey={DEFAULT_ACTIVE_KEYS}
                        alwaysOpen={true}
                    />
                </Card>
                <SettingConfirmationModal
                    content={modalContent()}
                    getAmpSettingValue={getAmpSettingValue}
                    savingSettings={savingSettings}
                    settingKey={settingKey!}
                    showConfirmationModal={showConfirmationModal}
                    title={modalTitle()}
                    onClose={handleModalClose}
                    onConfirm={handleConfirmModal}
                    onError={handleModalError}
                    onCallback={handleModalCallback}
                />
                <ConfirmationModal
                    modalTitle={'CHANGE ROLE'}
                    modalBodyTitle={'Are you sure you want to change the role of this user?'}
                    onPrimaryButtonClick={handleAccessChange}
                    primaryButtonLabel="CONFIRM"
                    show={showModal.accessUser}
                    onClose={handleCloseModal}
                    buttonVariant={'primary'}
                />
                <ConfirmationModal
                    modalTitle={'REMOVE USER'}
                    modalBodyTitle={'Are you sure you want to remove this user?'}
                    onPrimaryButtonClick={handleRemoveUser}
                    primaryButtonLabel="YES, REMOVE IT"
                    show={showModal.removeUser}
                    onClose={handleCloseModal}
                    buttonVariant={'danger'}
                />
            </div>
        );
    };
    return (
        <PageErrorHandlerAndLoaderWrapper
            error={settingsError}
            render={SettingsContent}
            isLoading={isLoading}
            handleRefresh={refreshTenantSettings}
        />
    );
};

export default SettingsPage;
