import React, { MouseEventHandler } from 'react';
import { Color } from './color';
import {
    ExpandableRowsComponent,
    PaginationChangePage,
    PaginationChangeRowsPerPage,
    TableColumn,
    TableStyles,
} from 'react-data-table-component/dist/src/DataTable/types';
import { Category, ConnectorStatus, CredentialStatus, FilterCriteria, Page } from '@ampsec/platform-client';

export type HealthMetrix = {
    header?: React.ReactNode;
    label: string;
    labelColor: Color;
    value: React.ReactNode;
    valueVariant?: 'body1' | 'caption2';
    valueColor?: Color;
};

export type ThresholdMapping = {
    label: string;
    color: Color;
};

export type DataProp = {
    label: string;
    value: number;
    valueColor?: Color;
    labelColor?: Color;
};

export type UserType = {
    severity: string;
    issueType: string;
    more: string;
    createdAt: string;
    numberOfEngagements: number;
    displayValue: string;
    status: string;
};

export type userHeader = {
    firstName: string;
    lastName: string;
    position: string;
    startDate: Date;
    department: string;
};

export type securityHealth = {
    value: number;
};

export type topRiskContributors = {
    label: string;
    value: number;
    ts: string;
};

export type MetricKind = 'BASE' | 'DESCRIPTIVE' | 'TRENDING';

export type BaseMetric = {
    kind: MetricKind;
    label: string;
    value: number;
};

export type DescriptiveMetric = BaseMetric & {
    ctxLabel: string;
    ctxValue: number;
};

export type TrendingMetric = BaseMetric & {
    delta: number;
};

export type MetricResults = {
    [kind: string]: MetricResults | BaseMetric | DescriptiveMetric | TrendingMetric;
};

export type ReportResultUpsertDto = {
    extKey: string;
    uid?: string;
    department?: string | null;
    ts: string;
    results: MetricResults;
};
export type ResultObjectType = {
    results: MetricResults;
};

export interface BaseMetricResults {
    [key: string]: BaseMetric;
}

export interface TrendingMetricResults {
    [key: string]: TrendingMetric;
}

export interface DescriptiveMetricResults {
    [key: string]: DescriptiveMetric;
}

export type FilterDropdownProps = {
    items: React.ReactNode[];
    options: {
        [key: string]: string[];
    };
    selectedOptions: {
        [key: string]: string[];
    };
    handleCheckboxChange: (option: string, isChecked: boolean, text: string) => void;
    handleClearClick: (item: string) => MouseEventHandler<HTMLButtonElement>;
    handleResetClick: () => void;
    handleSaveClick: () => void;
    handleApplyClick: () => void;
    filterWithActions: boolean;
    closeDropdown: () => void;
    isDisabled: boolean;
    hasSearchBar?: boolean;
};

export interface FilterButtonProps extends FilterDropdownProps {}

export type FilterItemProps = Pick<
    FilterDropdownProps,
    'selectedOptions' | 'handleCheckboxChange' | 'handleClearClick' | 'handleApplyClick' | 'isDisabled' | 'hasSearchBar'
> & {
    options: string[] | undefined;
    text: string;
};
export interface ActionButtonProps {
    selectedOptions: {
        [key: string]: string[];
    };
    handleResetClick: () => void;
    handleSaveClick: () => void;
}
export type FlowType = {
    id: number;
    title: string;
    target: string;
    metrics: {
        triggeredAt: string;
        activeCount: number;
        total: number;
    };
    status: string;
};

export type ChipProps = {
    value: string;
    variant: 'user_score' | 'top_risk_issue' | 'severity' | 'employee_risk_variant' | 'status';
    image?: string;
};
export type Dataset = {
    data: number[];
    backgroundColor: string;
    borderColor: string;
    name: string;
};

export interface DonutGraphDataSet {
    data: number[];
    backgroundColor: string[];
    borderColor: string;
    name: string;
}

export type Coordinates = {
    x: number;
    y: number;
};

export type GridType =
    | 'users'
    | 'findings'
    | 'api/v1/flows'
    | 'findingsInsights'
    | 'usersInsights'
    | 'flowSpecs'
    | 'findingSpecs'
    | 'customActions'
    | 'agents'
    | 'asset';

export interface PaginationComponentProps {
    onPageNumberClick: React.MouseEventHandler<HTMLButtonElement> | undefined;
    onNextButtonClick: MouseEventHandler<SVGElement> | undefined;
    onBackButtonClick: MouseEventHandler<SVGElement> | undefined;
    previousDisabled: boolean;
    nextDisabled: boolean;
    pageItems: number[];
    currentPage: number;
    pages: number;
    estimated?: number;
    onFirstPageClick: MouseEventHandler<SVGElement> | undefined;
    onLastPageClick: MouseEventHandler<SVGElement> | undefined;
}

export type CustomPaginationProps = {
    rowsPerPage: number;
    rowCount: number;
    onChangePage: PaginationChangePage;
    onChangeRowsPerPage: PaginationChangeRowsPerPage;
    currentPage: number;
    rowsPerPageOptions?: number[];
    estimated?: number;
};

export type TableSubHeaderProps<T> = {
    setSelectedOptions: React.Dispatch<
        React.SetStateAction<{
            [key: string]: string[];
        }>
    >;

    onSearchChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    searchInputValue: string;
    selectedOptions?: { [key: string]: string[] };
} & Pick<
    DataTableProps<T>,
    'columns' | 'subHeaderVariant' | 'selectedRows' | 'subHeaderContent' | 'filterColumns' | 'placeHolder'
> &
    Required<Pick<DataTableProps<T>, 'dropdownOptions'>>;

export type DataTableProps<T> = {
    data?: T[];
    customStyles?: TableStyles;
    expandableRows?: boolean;
    expandableRowsComponent?: ExpandableRowsComponent<T> | undefined;
    cacheKey: GridType;
    columns: TableColumn<T>[];
    defaultSortId?: string | undefined;
    rowsPerPageOptions: number[];
    tableBodyHeight?: string | undefined;
    showSubHeader?: boolean;
    optionsFields?: string[];
    titleComponent?: JSX.Element;
    transformer: (dataItem: any) => Page<any>;
    filter?: FilterCriteria;
    isMock?: boolean; // Temporary hack to get the data from mock API
    dropdownOptions?: {
        [key: string]: string[];
    };
    filterColumns?: string[];
    selectableRows?: boolean;
    pagination?: boolean;
    subHeaderVariant?: SubHeaderVariant;
    subHeaderContent?: React.ReactNode;
    selectedRows?: T[];
    onSelectedRowsChange?: (selected: { allSelected: boolean; selectedCount: number; selectedRows: T[] }) => void;
    clearSelectedRows?: boolean;
    refreshTableData?: boolean;
    dataIsLoading?: boolean;
    updateUrl?: boolean;
    onChangePage?: (currentPage: number) => void;
    onRowChange?: (rowsOption: number) => void;
    currentPage?: number;
    currentLimit?: number;
    paginationTotalRows?: number;
    isInfiniteScroll?: boolean;
    onRowDoubleClicked?: ((row: T, e: React.MouseEvent<Element, MouseEvent>) => void) | undefined;
    placeHolder?: string;
    searchKey?: string;
    operation?: string;
    defaultSortOrder?: 'ASC' | 'DESC';
    componentIdentifier?: string;
    noContextMenu?: boolean;
    estimated?: number;
};

export type TabProps = {
    title: string;
    link: string;
    eventKey: string;
};

export type AvatarWithNameType = {
    name: string;
    height: string;
    width: string;
};

export type AvatarWithImageType = {
    image: string;
};

export type AvatarWithImageAndHeightType = {
    image: string;
    height: string;
};

export type ShareSecurityPostureType = {
    id: number;
    name: string;
    addedDate: string;
    emailId: string;
    lastLoggedIn: string;
    access: string;
    status: string;
};

export type HealthScoreConfigurationType = {
    id: any;
    autoCompleteData?: MenuItem[];
    isExpanded?: boolean;
    selectedAutoCompleteValues?: MenuItem[];
};

export type InvitedUserType = {
    id: string;
    email: string;
};

export type SortType = 'ASC' | 'DESC' | null;

export type GridStateType = {
    sortColumn: null | string;
    sortDirection: SortType;
    filters: {
        [key: string]: string[];
    };
};

export type HandleTableFiltersProps = Pick<GridStateType, 'sortColumn' | 'sortDirection'> & {
    filteredColumn: keyof GridStateType['filters'] | null;
    filters: string[];
    filterMap?: Record<string, string>;
};

export type Filterable =
    | {
          filterable: true;
          filterOptions: string[];
          selectedFilterOptions: string[];
      }
    | {
          filterable: false;
          filterOptions?: never;
          selectedFilterOptions?: never;
      };

type Sortable =
    | {
          sortable: true;
          selectedSortDirection: SortType;
          sortHighLabel?: string;
          sortLowLabel?: string;
      }
    | {
          sortable: false;
          selectedSortDirection?: never;
          sortHighLabel?: never;
          sortLowLabel?: never;
      };

export type FindingsGridHeaderFilterProps = {
    columnName: string;
    text: string;
    handleTableFilters: (_props: HandleTableFiltersProps) => void;
    position?: 'start' | 'center' | 'end';
    customDisplayOptions?: Record<string, string>;
    onClear?: (sortColumn: string) => void;
    hasSearchBar?: boolean;
} & Filterable &
    Sortable;

export type FilterActionButtonsProps = {
    clearDisabled?: boolean;
    applyDisabled?: boolean;
    onClearClick: () => void;
    onApplyClick: () => void;
};

export type FilterComponentProps<T> = {
    options: T[];
    selectedOptions: T[];
    handleCheckbox: (event: React.ChangeEvent<HTMLInputElement>) => void;
    header?: boolean;
    customDisplayOptions?: Record<string, string>;
    hasSearchBar?: boolean;
};

export type SortComponentProps = {
    handleSort: (_sortDirection: SortType) => void;
    sortDirection: SortType;
    sortHighLabel?: string;
    sortLowLabel?: string;
};

export type EngagementDashboardColumnType = {
    id: number;
    lastHealed: string;
    name: string;
    domain: string;
    issueName: string;
    noOfEngagements: string;
};

export type UserGroupType = {
    id: number;
    groupName: string;
    numberOfEmployees: number;
    description: string;
};

export type SelectedUserGroupType = {
    id: number;
    name: string;
    addedDate: string;
    description: string;
    sourceType: string;
    close: string;
};
export type AddUserType = {
    id: number;
    name: string;
    firstName: string;
    lastName: string;
    image?: string;
    emailId: string;
    department: string;
    position: string;
};

export type MenuItem = {
    id: any;
    value: string;
    displayValue?: string;
    icon?: string;
    inclusive?: boolean;
    disabled?: boolean;
    cid?: string | null;
    kind?: string;
    link?: string;
    [key: string]: unknown | undefined;
};

export type AutoCompleteItem = {
    id?: string;
    value: string;
    icon?: string;
    inclusive?: boolean;
    [key: string]: unknown | undefined;
};

export type SubHeaderVariant =
    | 'with-button'
    | 'filter-with-actions'
    | 'filter-without-actions'
    | 'header-with-refresh-button';

export type SeverityType = 'success' | 'error' | 'info' | 'warning';
interface MetricsProps {
    score: number;
    scoreDelta: string;
    rank: string;
}

export interface UserDetailContentType {
    id: string;
    pictureUrl: string;
    firstName: string;
    lastName: string;
    managerEmail: string;
    department: string;
    title: string;
    startDate: string;
    metrics: MetricsProps;
    riskContributors: string;
    organization: string;
    score: string;
    emails: string[];
}

export type CardVariant = 'active' | 'disabled' | 'error' | 'custom';

export type InstallationInstructionsData = {
    data: {};
    content: Array<{
        data: {};
        content: Array<{
            data: {};
            marks: any[];
            value: string;
            nodeType: string;
        }>;
        nodeType: string;
    }>;
};

export type DynamicTabsData = {
    tabs: Array<{
        name: string;
        type: string;
        variant: string;
        contentfulKey: string;
    }>;
    strategy: string;
};
export type LogoData = {
    fields: {
        title: string;
        description: string;
        file: {
            url: string;
        };
        fileName: string;
        contentType: string;
    };
};

export type IntegrationData = {
    name: string;
    providerKey: string;
    logo: LogoData;
    tileDescription: string;
    overview: string;
    installationInstructions: InstallationInstructionsData;
    termsOfUseLink: string;
    privacyStatementLink: string;
    supportLink: string;
    formTextKeys: {};
    tags: string[];
    overviewLong: string;
    dynamicTabs: DynamicTabsData;
};
export type StatusType = 'active' | 'disabled' | 'error' | 'sync';
export interface InstalledVendorProps {
    category: Category[];
    domain: string;
    id: string;
    imageUrl: string;
    status: ConnectorStatus;
    title: string;
    cid: string;
    credentialStatus: CredentialStatus;
    syncTime?: string;
    lastRunTime?: string;
    provider: {
        displayValue: string;
    };
}

export type PromptType = {
    id: string;
    value: string;
    query?: Object;
    avatar?: string;
    saved?: boolean;
    disabled?: boolean;
    onSaveQuery?: (checked: boolean, prompt: SavedQueryType) => void;
    onViewQueryClick: (promptId: string) => void;
};

export type SavedQueryType = {} & Pick<PromptType, 'id' | 'value'>;

export type AllowedColors = 'vizualization-purple' | 'vizualization-green-01' | 'vizualization-blue';

export interface AutoCompleteMenuItem {
    id: string;
    value: string;
    icon: string;
    disabled: boolean;
    onClose?: () => void;
}

export type AllowedExpandableEngagementColors =
    | 'vizualization-purple'
    | 'vizualization-green-01'
    | 'vizualization-blue';

export enum EngagementPillsCategory {
    DESIGN = 'DESIGN',
    ESCALATE = 'ESCALATE',
    CONCLUDE = 'CONCLUDE',
}

export type CanvasType = {
    id: string;
    name: string;
    dropdownText?: string;
    value?: React.ReactNode;
    disabled: boolean;
    isDraggable: boolean;
    category: EngagementPillsCategory;
};

export type OsType = 'windows' | 'mac' | 'linux';

export type Row = {
    id: number;
    selectedValue?: string;
    included: boolean;
    autoCompleteData: MenuItem[];
};

export type StepperType = { stepperName: string; stepperContent: React.ReactNode };

export type FormData = {
    actionName: string;
    description: string;
    url: string;
    selectedMethod: string;
    selectedPayload: string;
    selectedPayloadValue: string;
    headers: { key: string; value: string }[];
    parameters: { key: string; value: string }[];
};

export type ActionItemType = {
    id: string;
    displayValue: string;
    serviceKey: { logo: string; displayValue: string } | null;
};

export enum AmpSentryTags {
    'EMPTY_RESPONSE' = 'empty.response',
}
