import { BackButton } from 'Components/molecules';
import { FindingDetailContent } from 'Components/organisms/FindingDetailContent';

const FindingDetailsPage = () => {
    return (
        <div className="user-specific-page">
            <div className="user-specific-page__content d-flex flex-column g-3 h-100">
                <BackButton />
                <FindingDetailContent />
            </div>
        </div>
    );
};

export default FindingDetailsPage;
